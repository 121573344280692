import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { ConnectServerService } from '../../../services/connect-server.service';
import { Connect } from '../../../classes/connect';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ApiResponse } from '../../../interfaces/api-response';
import { PopupDialogService } from '../../../services/popup-dialog.service';

interface User {
  id: number;
  name: string;
  email: string;
}

interface Permission {
  idcomponent: number;
  component_name: string;
  function_name: string;
  idfunction: number;
  authorization: number;
}

interface Functionality {
  id: number;
  name: string;
  description: string;
}

@Component({
  selector: 'app-permissions',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTableModule,
    MatTooltipModule,
    MatButtonModule
  ],
  templateUrl: './permissions.component.html',
  styleUrl: './permissions.component.scss'
})
export class PermissionsComponent {

  permissionsList: Functionality[] = [];

  permissionsForm: FormGroup = this.fb.group({
    authorizations: this.fb.array([])
  })

  constructor(private fb: FormBuilder, private connectServerService: ConnectServerService,
    private popupService: PopupDialogService
  ) { }

  ngOnInit(): void {
    // PRENDI ED ASSEGNA LA LISTA DI PERMESSI PER LE COLONNE
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'listFunctionalities', {
    })
      .subscribe((val: Functionality[]) => {
        this.permissionsList = val;
        this.popolateArray();
      });
  }

  private createAuthorizationsField(content: { user: User, permissions: Permission[] }): FormGroup {
    return this.fb.group({
      user: this.createUserForm(content.user),
      permissions: this.addPermissionsArray(content.permissions)
    });
  }

  private createPermissionsField(permission: Permission): FormGroup {
    return this.fb.group({
      idcomponent: [permission.idcomponent],
      component_name: [permission.component_name],
      function_name: [permission.function_name],
      idfunction: [permission.function_name],
      authorization: [permission.authorization, Validators.required]
    })
  }

  private createUserForm(user: User): FormGroup {
    return this.fb.group({
      id: [user.id],
      name: [user.name]
    })
  }

  private addPermissionsArray(permissions: Permission[]): FormArray {
    const permissionArray = this.fb.array([]) as FormArray;
    permissions.forEach((item: Permission) => {
      permissionArray.push(this.createPermissionsField(item));
    });
    return permissionArray;
  }

  // Getter per il FormArray delle permissions
  get authorizations(): FormArray {
    return this.permissionsForm.get('authorizations') as FormArray;
  }

  // Funzione per ottenere il FormArray 'permissions' per un chronology specifico
  getPermissionsControls(index: number): FormArray {
    return this.authorizations.at(index).get('permissions') as FormArray;
  }

  private popolateArray() {
    this.authorizations.clear();
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'listUsersFunctionalitiesPermissions', {
    })
      .subscribe((val: {user: User, permissions: Permission[]}[]) => {
        val.forEach(element => {
          this.authorizations.push(this.createAuthorizationsField(element));
        });
        // console.log(val);

      });
  }

  save() {
    let index = 0;
    this.authorizations.controls.forEach((item: any) => {
      this.getPermissionsControls(index).controls.forEach(element => {
        if (element.get('authorization')?.value == true) {
          element.get('authorization')?.setValue(1);
        }
        else if (element.get('authorization')?.value == false) {
          element.get('authorization')?.setValue(0);
        }

        if (element.get('readonly')?.value == true) {
          element.get('readonly')?.setValue(1);
        }
        else if (element.get('readonly')?.value == false) {
          element.get('readonly')?.setValue(0);
        }
      });
      index += 1;
    });

    // console.log(this.permissionsForm.getRawValue());
    const permissions = this.permissionsForm.getRawValue().authorizations;
    this.connectServerService.postRequest<ApiResponse<{}>>(Connect.urlServerLaraApi, 'permissionsFunctionsUsers', {
      listPermissions: permissions
    }).subscribe(
      (esito: ApiResponse<{}>) => {
        this.popupService.popupDialog(esito);
        this.popolateArray();
      }
    )
  }

}
