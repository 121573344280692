import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectOptions } from '../../interfaces/select-options';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { CheckFormValidityService } from '../../../../services/check-form-validity.service';
import { PopupDialogService } from '../../../../services/popup-dialog.service';
import { ApiResponse } from '../../../../interfaces/api-response';
import { Connect } from '../../../../classes/connect';

@Component({
  selector: 'app-location-data',
  standalone: true,
  imports: [
    MatExpansionModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './location-data.component.html',
  styleUrl: './location-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationDataComponent {

  readonly panelOpenState = signal(false);

  @Input() idarticle: number = 0;
  @Input() listLocation: SelectOptions[] = [];

  saved: boolean = false;
  submitted: boolean = false;

  locationForm: FormGroup;

  constructor(private fb: FormBuilder, private connectServerService: ConnectServerService,
    private popupDialogService: PopupDialogService, private checkFormValidityService: CheckFormValidityService) {
    this.locationForm = this.fb.group({
      original_location: [null], // Campo originale
      museumInventory: this.fb.array([this.createMuseumInventory()])  // FormArray con un set di campi iniziale
    });
  }

  ngOnInit(): void {
    this.getLocationInfo();
  }

  // Funzione che crea un singolo gruppo di campi per il museo
  createMuseumInventory(): FormGroup {
    return this.fb.group({
      id: [0],
      number: [null, [Validators.required, Validators.maxLength(100)]],
      date: [null, [Validators.required, Validators.maxLength(50)]],
      location: [null, [Validators.required, Validators.maxLength(50)]],
    });
  }

  createMuseumInventoryValues(content: {id: number, number: string, date: string, location: string}): FormGroup {
    return this.fb.group({
      id: [content.id],
      number: [content.number, [Validators.required, Validators.maxLength(100)]],
      date: [content.date, [Validators.required, Validators.maxLength(50)]],
      location: [content.location, [Validators.required, Validators.maxLength(50)]]
    })
  }  

  // Restituisce il FormArray per una gestione più semplice
  get museumInventory(): FormArray {
    return this.locationForm.get('museumInventory') as FormArray;
  }

  // Aggiunge un nuovo gruppo di campi al FormArray
  addMuseumInventory() {
    this.museumInventory.push(this.createMuseumInventory());
  }

  addMuseumInventoryValues(content: {id: number, number: string, date: string, location: string}) {
    this.museumInventory.push(this.createMuseumInventoryValues(content));
  }

  // Rimuove un gruppo di campi dal FormArray, lasciando sempre almeno uno
  removeMuseumInventory(index: number) {
    if (this.museumInventory.length > 1) {
      this.museumInventory.removeAt(index);
    }
  }

  private getLocationInfo() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'locationInfo', { idarticle: this.idarticle })
      .subscribe(
        (val: ApiResponse<{ locationForm: any }>) => {
          this.locationForm.patchValue(val.data.locationForm)
          this.museumInventory.clear();
          this.createNestedFields(val.data.locationForm.museumInventory);
          this.saved = this.checkFormValidityService.areAllRequiredFieldsFilled(this.locationForm);
          if(this.saved) {
            this.panelOpenState.set(false);
          }
          else {
            this.panelOpenState.set(true);
          }
        }
      )
  }

  createNestedFields(museumInventory: {id: number, number: string, date: string, location: string}[]) {
    if(museumInventory.length == 0) {
      this.addMuseumInventory();
    }
    else {
      museumInventory.forEach(element => {
        this.addMuseumInventoryValues(element);
      });
    }
  }

  private setLocationInfo() {
    const formValues = this.locationForm.getRawValue();
    this.connectServerService.postRequest(Connect.urlServerLaraApi, 'updateLocationInfo',
      { idarticle: this.idarticle, infoLocation: formValues })
      .subscribe(
        (val: ApiResponse<null>) => {
          this.popupDialogService.popupDialog(val);
          this.getLocationInfo();
        }
      )
  }

  save() {
    this.submitted = true;
    if(this.locationForm.valid) {
      this.setLocationInfo();
    }
  }
}
