import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectOptions } from '../../interfaces/select-options';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { CheckFormValidityService } from '../../../../services/check-form-validity.service';
import { PopupDialogService } from '../../../../services/popup-dialog.service';
import { Connect } from '../../../../classes/connect';
import { ApiResponse } from '../../../../interfaces/api-response';
import { SelectTechnicalData } from '../../interfaces/select-technical-data';

@Component({
  selector: 'app-technical-data',
  standalone: true,
  imports: [
    MatExpansionModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './technical-data.component.html',
  styleUrl: './technical-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechnicalDataComponent {

  readonly panelOpenState = signal(false);

  @Input() idarticle: number = 0;
  @Input() listTechnicalData: SelectTechnicalData | null = null;

  saved: boolean = false;
  submitted = false;
  technicalDataForm: FormGroup;

  constructor(private fb: FormBuilder, private connectServerService: ConnectServerService,
    private popupDialogService: PopupDialogService, private checkFormValidityService: CheckFormValidityService) {
    this.technicalDataForm = this.fb.group({
      techniques: this.fb.array([this.createTechnique()]),
      measures: this.fb.array([this.createMeasures()])
    })
  }

  ngOnInit(): void {
    this.getTechnicalDataInfo();
  }

  // Funzione che crea un singolo gruppo di campi
  createTechnique(): FormGroup {
    return this.fb.group({
      id: [0],
      technique: [null, [Validators.required, Validators.maxLength(150)]]
    });
  }

  createMeasures(): FormGroup {
    return this.fb.group({
      id: [0],
      unit: [null, Validators.required],
      height: [null, [Validators.required, Validators.maxLength(6)]],
      width: [null, [Validators.required, Validators.maxLength(6)]],
      depth: [null, [Validators.required, Validators.maxLength(6)]],
      diameter: [null, [Validators.required, Validators.maxLength(6)]],
      length: [null, [Validators.required, Validators.maxLength(6)]],
      thickness: [null, [Validators.required, Validators.maxLength(6)]],
      weight: [null, [Validators.required, Validators.maxLength(6)]],
      others: this.fb.array([this.createOthers()]),
      missing: [null, Validators.required],
      validity: [null, Validators.required],
    });
  }

  createOthers(): FormGroup {
    return this.fb.group({
      id: [0],
      other: [null, [Validators.required, Validators.maxLength(250)]]
    });
  }

  // Restituisce il FormArray per una gestione più semplice
  get techniques(): FormArray {
    return this.technicalDataForm.get('techniques') as FormArray;
  }

  get measures(): FormArray {
    return this.technicalDataForm.get('measures') as FormArray;
  }

  // Aggiunge un nuovo gruppo di campi al FormArray
  addTechnique() {
    this.techniques.push(this.createTechnique());
  }

  addMeasures() {
    this.measures.push(this.createMeasures());
  }

  addOthers(index: number) {
    const otherArray = this.measures.at(index).get('others') as FormArray;
    otherArray.push(this.createOthers());
  }

  // Rimuove un gruppo di campi dal FormArray, lasciando sempre almeno uno
  removeTechniques(index: number) {
    if (this.techniques.length > 1) {
      this.techniques.removeAt(index);
    }
  }

  removeMeasures(index: number) {
    if (this.measures.length > 1) {
      this.measures.removeAt(index);
    }
  }

  removeOthers(index: number, othersIndex: number) {
    const othersArray = this.measures.at(index).get('others') as FormArray;
    if (othersArray.length > 1) {
      othersArray.removeAt(othersIndex);
    }
  }

  // Funzione per ottenere il FormArray 'attributions' per un chronology specifico
  getMeasuresControl(index: number): FormArray {
    return this.measures.at(index).get('others') as FormArray;
  }

  private getTechnicalDataInfo() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'technicalDataInfo', { idarticle: this.idarticle })
      .subscribe(
        (val: ApiResponse<{ technicalDataForm: any }>) => {
          this.technicalDataForm.patchValue(val.data.technicalDataForm);
          this.saved = this.checkFormValidityService.areAllRequiredFieldsFilled(this.technicalDataForm);
          if(this.saved) {
            this.panelOpenState.set(false);
          }
          else {
            this.panelOpenState.set(true);
          }
        }
      )
  }

  private setTechnicalDataInfo() {
    const formValues = this.technicalDataForm.getRawValue();
    // console.log('val:', formValues);
    this.connectServerService.postRequest(Connect.urlServerLaraApi, 'updateTechnicalData',
      { idarticle: this.idarticle, infoTechnical: formValues })
      .subscribe(
        (val: ApiResponse<null>) => {
          this.popupDialogService.popupDialog(val);
          this.getTechnicalDataInfo();
        }
      )
  }

  save() {
    this.submitted = true
    if(this.technicalDataForm.valid) {
      this.setTechnicalDataInfo();
    }
  }

}
