import { Component, inject, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { ConnectServerService } from '../../../services/connect-server.service';
import { Connect } from '../../../classes/connect';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ApiResponse } from '../../../interfaces/api-response';
import { PopupDialogService } from '../../../services/popup-dialog.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-import-artistic-definition',
  standalone: true,
  imports: [
    MatTableModule, CommonModule, MatIconModule, MatButtonModule
  ],
  templateUrl: './import-artistic-definition.component.html',
  styleUrl: './import-artistic-definition.component.scss'
})
export class ImportArtisticDefinitionComponent implements OnInit {
  readonly connectServerService = inject(ConnectServerService);
  readonly popupDialogService = inject(PopupDialogService);
  displayedColumns: string[] = ['id', 'tableName', 'code', 'fileName', 'dateImport', 'action'];
  dataSource$!: Observable<{ id: number, name: string, code: string, filename: string, updated_at: string }[]>;

  ngOnInit(): void {
    this.dataSource$ = this.connectServerService.getRequest
    <{ id: number, tableName: string, code: string, fileName: string, dateImport: string }[]>(Connect.urlServerLaraApi, 'listArtisticTable', {});
  }

  uploadFiles(event: any, tablename: string, id: number): void {
    const files: File[] = event.target.files;
    let messaggio = '';
    for (const file of files) {
      if (file) {
        if (file.size < Connect.sizeFileExcel) {
          const formData = new FormData();
          formData.append('allegato', file);
          formData.append('table_name', tablename);
          formData.append('idtable', String(id));
          const upload$ = this.connectServerService
            .postRequest(Connect.urlServerLaraApi, 'importArtisticDefinition', formData);
          upload$
            .subscribe(
              (res: ApiResponse<any>) => {
                this.popupDialogService.popupDialog(res)
                this.ngOnInit();
              }
            );
        } else {
          messaggio = messaggio + 'Impossibile caricare il file ' + file.name
            + '\n';
        }
      }
    }
    if (messaggio.length > 0) {
      alert(messaggio + 'Dimensione consentita per ogni file: ' + Connect.str_sizeFileExcel + '\n');
    }
  }
}
