<div class="container-fluid fullscreen-modal">
  <div class="row justify-content-end align-items-center mt-1 borderBottom">
    <!-- <div class="col-6 text-start">
      <button class="btn btn-outline-dark me-2" (click)="zoomIn()">
        <i class="bi bi-zoom-in"></i>
      </button>
      <span class="d-none d-md-inline">Zoom</span>
      <button class="btn btn-outline-dark ms-2" (click)="zoomOut()">
        <i class="bi bi-zoom-out"></i>
      </button>
    </div> -->
    <div class="col-6 text-end mb-1">
      <button class="btn btn-outline-dark" (click)="closeDialog()">
        <i class="bi bi-x"></i>
      </button>
    </div>
  </div>
</div>
<div class="container-fluid modal-container p-0" style="max-height: 78vh; z-index: 10000; overflow-x: hidden;">
  <pdf-viewer #pdfViewer [src]="currentSrc!" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="true"
    [zoom]="0.8" [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true"
    [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
    style="width: auto; height: 78vh;"></pdf-viewer>
</div>