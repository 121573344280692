<div class="container-fluid p-4 p-lg-5" style="min-height: 80%; min-width: 350px; max-width: 1400px;">
    <div class="row justify-content-center align-items-center p-1 p-lg-2">
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 63%; left: 45%;">
                GESTIONE SCHEDA
            </span>
        </div>
        <div class="col-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    GESTIONE SCHEDA
                </span>   
            </div>
        </div>
        <div class="col-12 text-center">
            <span style="font-size: small;">Campi obbligatori <sup>*</sup></span>
        </div>
    </div>

    <form [formGroup]="articleForm">

        <div class="row justify-content-center ps-2 pe-2" style="border-bottom: 1px solid lightgrey;">
            <div class="col-12 col-md-4 p-1">
                <label for="code" class="form-label" style="font-weight: bold;">
                    Codice Articolo<sup>*</sup>
                </label>
                <div class="input-group mb-2" style="height: 40px;">
                    <input type="text" class="form-control" placeholder="Codice Univoco" id="code"
                        formControlName="code">
                </div>
            </div>

            <div class="col-12 col-md-4 p-1">
                <label for="category" class="form-label" style="font-weight: bold;">
                    Categoria<sup>*</sup>
                </label>
                <select class="form-select mb-2" aria-label="Default select example" name="category"
                    formControlName="category">
                    <option [ngValue]="null" disabled selected>Seleziona una categoria</option>
                    <option *ngFor="let item of categoryOptions" [ngValue]="item">{{item.title}}</option>
                </select>
            </div>

            <div class="col-12 col-md-4 p-1">
                <label for="subCategory" class="form-label" style="font-weight: bold;">Sottocategoria</label>
                <select class="form-select mb-2" aria-label="Default select example" formControlName="subCategory">
                    <option [ngValue]="null">Seleziona una categoria</option>
                    <option *ngFor="let option of subcategoryOptions" [ngValue]="option" class="form-control">
                        {{option.title}}
                    </option>
                </select>
            </div>
        </div>

        <div class="row p-2" style="border-bottom: 1px solid lightgrey;">
            <div class="col-12 col-md-6 text-start p-1">
                <label for="title" class="form-label" style="font-weight: bold;">
                    Titolo<sup>*</sup>
                </label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Titolo della scheda" id="title"
                        formControlName="title">
                </div>
            </div>

            <div class="col-12 p-1">
                <label for="description" class="form-label" style="font-weight: bold;">Descrizione</label>
                <div class="input-group">
                    <textarea type="text" class="form-control" placeholder="Descrizione della scheda" id="description"
                        formControlName="description" rows="4"></textarea>
                </div>
            </div>

            <!-- <div class="col-12 col-md-6 p-1 p-md-2 p-xl-3 categorie">

            <label for="description" class="form-label" style="font-weight: bold;">Città</label>
            <select class="form-select mb-2" aria-label="Default select example" formControlName="city">
                <option [value]="null">Seleziona una città</option>
                <option *ngFor="let option of cities$ | async" class="form-control">
                    {{option.name}}
                </option>
            </select> -->
            <div class="col-12 col-sm-6 p-1">

                <label class="form-label" style="font-weight: bold;">Data</label><br>
                <div class="btn-group btn-group-toggle mb-2" role="group" aria-label="selectFormat">
                    <input type="radio" class="btn-check" name="yearFormat" id="date" autocomplete="off"
                        (click)="changeFormat('date')" [checked]="yearFormat === 'date'">
                    <label class="btn btn-outline-dark" for="date">Data</label>

                    <input type="radio" class="btn-check" name="yearFormat" id="year" autocomplete="off"
                        (click)="changeFormat('year')" [checked]="yearFormat === 'year'">
                    <label class="btn btn-outline-dark" for="year">Anno</label>

                    <input type="radio" class="btn-check" name="yearFormat" id="period" autocomplete="off"
                        (click)="changeFormat('period')" [checked]="yearFormat === 'period'">
                    <label class="btn btn-outline-dark" for="period">Periodo</label>
                </div>

                <div *ngIf="yearFormat == 'date'">
                    <div class="input-group mt-1 mb-3">
                        <input type="date" class="form-control" placeholder="GG/MM/AAAA" formControlName="date">
                    </div>
                    <!-- <mat-form-field>
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="date">
                        <mat-hint>GG/MM/AAAA</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field> -->
                </div>

                <div *ngIf="yearFormat == 'year'">
                    <div class="input-group mt-1 mb-3">
                        <input type="text" class="form-control" placeholder="Anno singolo" id="year"
                            formControlName="year" (input)="isCorrectYearLogic()"
                            [class.is-invalid]="articleForm.get('year')?.invalid && (articleForm.get('year')?.dirty || articleForm.get('year')?.touched)">
                    </div>
                </div>

                <div *ngIf="yearFormat == 'period'">
                    <div class="input-group mt-1 mb-3">
                        <input type="text" class="form-control" placeholder="Dal" id="yearFrom"
                            formControlName="yearFrom" (input)="isCorrectPeriodLogic()"
                            [class.is-invalid]="articleForm.get('yearFrom')?.invalid">
                        <input type="text" class="form-control" placeholder="Al" id="yearTo" formControlName="yearTo" (input)="isCorrectPeriodLogic()"
                        [class.is-invalid]="articleForm.get('yearTo')?.invalid">
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-6 p-1">
                <label class="form-label" style="font-weight: bold;">Visibilità Articolo</label><br>
                <div class="form-check form-switch" style="margin-bottom: 1.6rem;">
                    <input class="form-check-input form-control" type="checkbox" role="switch" id="public"
                        formControlName="public" checked style="cursor: pointer;" (change)="enableDisablePublication()">
                    <label class="form-check-label" for="public">Pubblico</label>
                </div>

                <div *ngIf="articleForm.get('public')!.value == 1">
                    <div class="input-group mb-2" style="height: 38px;">
                        <input type="text" class="form-control" placeholder="Anno Pubblicazione" id="public_year"
                            formControlName="public_year" (input)="isCorrectPublicYear()" [class.is-invalid]="articleForm.get('public_year')?.invalid &&
                            (articleForm.get('public_year')?.dirty || articleForm.get('public_year')?.touched)">
                    </div>
                </div>
            </div>
        </div>

        <div class="row pt-2 ps-2 pe-2">
            <div class="col-12 col-md-4 ps-1 pe-1 pb-1">
                <label for="city" class="form-label" style="font-weight: bold;">Città</label>
                <div class="input-group" style="height: 38px;">
                    <input type="text" matInput [matAutocomplete]="city" class="form-control" placeholder="Città"
                        id="city" formControlName="city"
                        [class.is-invalid]="articleForm.get('city')?.invalid && (articleForm.get('city')?.dirty || articleForm.get('city')?.touched)">
                </div>
            </div>
            <mat-autocomplete #city="matAutocomplete" [displayWith]="displayCityName">
                <mat-option *ngFor="let option of filteredCity$ | async" [value]="option">
                    <div>
                        {{option.name}}
                    </div>
                </mat-option>
            </mat-autocomplete>


            <!-- <mat-form-field class="col-12 col-md-6 p-1 p-md-2 p-xl-3 categorie">
            <mat-label>Città</mat-label>
            <input matInput class="" type="search" placeholder="Almeno due lettere" formControlName="city"
                [matAutocomplete]="city">
        </mat-form-field>
        <mat-autocomplete #city="matAutocomplete" [displayWith]="displayCityName">
            <mat-option *ngFor="let option of filteredCity$ | async" [value]="option">
                <div>
                    {{option.name}}
                </div>
            </mat-option>
        </mat-autocomplete> -->

            <div class="col-12 col-md-4 ps-1 pe-1 pb-1">
                <label for="pointOfInterest" class="form-label" style="font-weight: bold;">
                    Luogo d'interesse
                </label>
                <div class="input-group" style="height: 40px;">
                    <input type="text" class="form-control" placeholder="Luogo" id="pointOfInterest"
                        formControlName="pointOfInterest">
                </div>
            </div>

            <div class="col-12 col-md-4 ps-1 pe-1 pb-1">
                <label for="location" class="form-label" style="font-weight: bold;">
                    Luogo di conservazione<sup>*</sup>
                </label>
                <div class="input-group" style="height: 40px;">
                    <input type="text" class="form-control" placeholder="Luogo" id="location"
                        formControlName="location">
                </div>
            </div>

        </div>
        <div class="row p-2" style="border-bottom: 1px solid lightgrey;">

            <div class="col-6 col-md-3 ps-1 pe-1 pb-1">
                <label for="floor" class="form-label" style="font-weight: bold;">
                    Piano<sup>*</sup>
                </label>
                <div class="input-group" style="height: 40px;">
                    <input type="text" class="form-control" placeholder="Piano edificio" id="floor"
                        formControlName="floor">
                </div>
            </div>

            <div class="col-6 col-md-3 ps-1 pe-1 pb-1">
                <label for="room" class="form-label" style="font-weight: bold;">
                    Stanza<sup>*</sup>
                </label>
                <div class="input-group" style="height: 40px;">
                    <input type="text" class="form-control" placeholder="" id="room" formControlName="room">
                </div>
            </div>

            <div class="col-6 col-md-3 ps-1 pe-1 pb-1">
                <label for="rack" class="form-label" style="font-weight: bold;">
                    Scaffale<sup>*</sup>
                </label>
                <div class="input-group" style="height: 40px;">
                    <input type="text" class="form-control" placeholder="" id="rack" formControlName="rack">
                </div>
            </div>

            <div class="col-6 col-md-3 ps-1 pe-1 pb-1">
                <label for="case" class="form-label" style="font-weight: bold;">
                    Contenitore<sup>*</sup>
                </label>
                <div class="input-group" style="height: 40px;">
                    <input type="text" class="form-control" placeholder="" id="case" formControlName="case">
                </div>
            </div>
        </div>

        <div class="row justify-content-center align-items-center">
            <div *ngIf="!articleForm.valid" class="col-12 text-center mt-3 mb-1" style="font-size: small;">Compila
                correttamente tutti i
                campi per abilitare il caricamento</div>
            <div class="col-12 col-md-6 col-xl-4 mt-2 text-center">
                <button class="btn btn-outline-dark" type="submit" [disabled]="!articleForm.valid"
                    (click)="insertArticle()">Salva</button>
            </div>
        </div>

    </form>
</div>