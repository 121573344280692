import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ConnectServerService } from '../../../services/connect-server.service';
import { Connect } from '../../../classes/connect';
import { ApiResponse } from '../../../interfaces/api-response';
import { Category } from '../../../interfaces/article';

@Component({
  selector: 'app-inventory-historical',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './inventory-historical.component.html',
  styleUrl: './inventory-historical.component.scss'
})
export class InventoryHistoricalComponent {

  inventoryHistorical: any[] = [];

  categoryFilter = new FormControl<number | null>(null);
  categories: Category[] = [];

  constructor(private connectServerService: ConnectServerService) {
    this.getInventoryFromServer();
    this.getCategories();
  }

  getInventoryFromServer() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'inventoryHistorical', { idcategory: this.categoryFilter.value })
      .subscribe(
        (val: any) => {
          this.inventoryHistorical = val.data.historicalInventory;
          // Aggiungi dinamicamente la proprietà isOpen a ogni elemento
          this.inventoryHistorical.forEach(element => {
            element.isOpen = true; // Imposta il valore predefinito a true
          });
        }
      )
  }

  private getCategories() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'categories', {})
      .subscribe(
        (val: ApiResponse<{ categories: Category[] }>) => {
          this.categories = val.data.categories;
        }
      )
  }

  openClose(element: any) {
    element.isOpen = !element.isOpen;
  }

  ngAfterViewChecked() {
    this.adjustContentHeight();
  }

  private adjustContentHeight() {
    const elements = document.querySelectorAll('.content-wrapper');
    elements.forEach((element: any) => {
      if (element.classList.contains('open')) {
        element.style.height = `${element.scrollHeight}px`;
      } else {
        element.style.height = '0';
      }
    });
  }

}
