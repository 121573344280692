<div class="container p-1 p-md-2 p-lg-3" style="min-height: 80%; min-width: 350px; max-width: 1400px;">
  <div class="row p-2 justify-content-center align-items-center text-center"
    style="margin-left: 0 !important; margin-right: 0 !important;">
    <div class="col-12" style="position: relative;">
      <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto" style="width: 90%; height: auto;">
      <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 63%; left: 40%;">
        GESTIONE AUTORIZZAZIONI
      </span>
    </div>
    <div class="col-8 text-center">
      <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
        <span style="font-weight: bold; font-size: x-large; color: white;">
          GESTIONE AUTORIZZAZIONI
        </span>
      </div>
    </div>
    <div class="col-12 mt-2 mb-2 p-1 p-md-2 p-lg-3">
      <span>
        Assegnazione accesso alle pagine per ogni utente
      </span>
    </div>


    <div class="col-12 p-1 p-md-2 p-lg-3">
      <form [formGroup]="userForm">
        <div class="row justify-content-center align-items-center">
          <div class="col-12 col-md-6 col-lg-4">
            <label for="id" class="form-label" style="font-weight: bold;">
              Seleziona Utente
            </label>
            <select class="form-select mb-2" aria-label="Default select example" formControlName="id">
              <option [ngValue]="null" disabled>Seleziona Utente</option>
              <option *ngFor="let option of usersList" [ngValue]="option.id" class="form-control">
                {{option.name}} - {{option.email}}
              </option>
            </select>
          </div>
        </div>
      </form>
    </div>


    <div class="col-12 mt-2">
      <form [formGroup]="authorizationsForm">
        <div formArrayName="authorizations">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Id Componente</th>
                <th>Tipo</th>
                <th>Componente</th>
                <th>Pagina</th>
                <th>Path</th>
                <th>Sempre visibile</th>
                <th>Autorizzazione</th>
                <th>Readonly</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let authorization of authorizations.controls; let i = index" [formGroupName]="i">
                <td [ngClass]="{ 'bg-success-subtle': authorization.get('view_always')?.value == 1 }">{{
                  authorization.get('idcomponent')?.value }}</td>
                <td [ngClass]="{ 'bg-success-subtle': authorization.get('view_always')?.value == 1 }">{{
                  authorization.get('type')?.value === 0 ? 'Pagina' : authorization.get('type')?.value}}</td>
                <td [ngClass]="{ 'bg-success-subtle': authorization.get('view_always')?.value == 1 }">{{
                  authorization.get('component_name')?.value }}</td>
                <td [ngClass]="{ 'bg-success-subtle': authorization.get('view_always')?.value == 1 }">{{
                  authorization.get('page_name')?.value }}</td>
                <td [ngClass]="{ 'bg-success-subtle': authorization.get('view_always')?.value == 1 }">{{
                  authorization.get('path')?.value }}</td>
                <td [ngClass]="{ 'bg-success-subtle': authorization.get('view_always')?.value == 1 }">{{
                  authorization.get('view_always')?.value == 1 ? 'SI' : authorization.get('view_always')?.value}}
                </td>
                <td align="center" [ngClass]="{ 'bg-body-secondary': authorization.get('view_always')?.value == 1 }">
                  <input class="form-check-input" type="checkbox" formControlName="authorization">
                </td>
                <td align="center" [ngClass]="{ 'bg-body-secondary': authorization.get('view_always')?.value == 1 }">
                  <input class="form-check-input" type="checkbox" formControlName="readonly">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="userForm.get('id')?.value" class="row justify-content-center align-items-center p-2">
    <div class="col-12 col-md-6 col-lg-4 text-center">
      <button class="btn btn-outline-dark" (click)="save()">
        Salva
      </button>
    </div>
  </div>
</div>
