import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { Connect } from '../../../../classes/connect';
import { ApiResponse } from '../../../../interfaces/api-response';
import { SelectOptions } from '../../interfaces/select-options';
import { PopupDialogService } from '../../../../services/popup-dialog.service';
import { CheckFormValidityService } from '../../../../services/check-form-validity.service';

@Component({
  selector: 'app-object',
  standalone: true,
  imports: [
    MatExpansionModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './object.component.html',
  styleUrl: './object.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectComponent {

  readonly panelOpenState = signal(false);

  @Input() idarticle: number = 0;
  @Input() listObject: SelectOptions[] = [];

  optionsLevel2: SelectOptions[] = [];
  optionsLevel3: SelectOptions[] = [];

  saved: boolean = false;
  submitted: boolean = false;

  objectForm: FormGroup;

  constructor(private fb: FormBuilder, private connectServerService: ConnectServerService,
    private popupDialogService: PopupDialogService, private checkFormValidityService: CheckFormValidityService) {
    this.objectForm = this.fb.group({
      definition_level1: [null, Validators.required],
      definition_level2: [{ value: null, disabled: true }],
      definition_level3: [{ value: null, disabled: true }],
      identification: this.fb.array([this.createIdentificationField()], Validators.required),
      quantity: [null, [Validators.required, Validators.maxLength(3)]],
      title: this.fb.array([this.createIdentificationField()], Validators.required),
    });
  }

  ngOnInit(): void {
    this.logicForm();
    this.getObjectInfo();
  }

  // Crea un controllo per ogni identificazione e titolo
  createIdentificationField(): FormGroup {
    return this.fb.group({
      id: [0],
      content: [null, [Validators.required, Validators.maxLength(250)]]
      })
  }

  createTitleField(): FormGroup {
    return this.fb.group({
      id: [0],
      content: [null, [Validators.required, Validators.maxLength(250)]]
      })
  }

  createIdentificationFieldValues(id: number, content: string): FormGroup {
    return this.fb.group({
      id: [id],
      content: [content, [Validators.required, Validators.maxLength(250)]]
      })
  }

  createTitleFieldValues(id: number, content: string): FormGroup {
    return this.fb.group({
      id: [id],
      content: [content, [Validators.required, Validators.maxLength(250)]]
      })
  }

  get identificationFields(): FormArray {
    return this.objectForm.get('identification') as FormArray;
  }

  get titleFields(): FormArray {
    return this.objectForm.get('title') as FormArray;
  }

  // Aggiungi una nuova riga
  addIdentificationField() {
    this.identificationFields.push(this.createIdentificationField());
  }

  addTitleField() {
    this.titleFields.push(this.createTitleField());
  }

  addIdentificationFieldValues(id: number, content: string) {
    this.identificationFields.push(this.createIdentificationFieldValues(id, content));
  }

  addTitleFieldValues(id: number, content: string) {
    this.titleFields.push(this.createTitleFieldValues(id, content));
  }

  // Rimuovi una riga di identificazione, ma lascia almeno una
  removeIdentificationField(index: number) {
    if (this.identificationFields.length > 1) {
      this.identificationFields.removeAt(index);
    }
  }

  removeTitleField(index: number) {
    if (this.titleFields.length > 1) {
      this.titleFields.removeAt(index);
    }
  }

  isCorrectQuantity() {
    if (/^[0-9]+$/.test(this.objectForm.get('quantity')!.value!.toString()) && parseInt(this.objectForm.get('quantity')!.value) <= 999) {
      this.objectForm.get('quantity')?.setErrors(null);
    }
    else if (this.objectForm.get('quantity')!.value!.length < 3) {
      this.objectForm.get('quantity')?.setErrors({ letters: true });
    }
    else {
      this.objectForm.get('quantity')?.setErrors({ maxlength: true });
    }
  }

  getObjectInfo() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'objectInfo', { idarticle: this.idarticle })
      .subscribe((val: ApiResponse<{ objectForm: any }>) => {
        this.identificationFields.clear();
        this.titleFields.clear();
        this.objectForm.patchValue(val.data.objectForm);
        this.createNestedFields(val.data.objectForm.identification, val.data.objectForm.title);
        this.saved = this.checkFormValidityService.areAllRequiredFieldsFilled(this.objectForm);
        if(this.saved) {
          this.panelOpenState.set(false);
        }
        else {
          this.panelOpenState.set(true);
        }
      })
  }

  private createNestedFields(identification: {id: number, content: string}[], title: {id: number, content: string}[]) {
    if(identification.length == 0) {
      this.addIdentificationField();
    }
    else {
      identification.forEach(element => {
        this.addIdentificationFieldValues(element.id, element.content);
      });
    }
    
    if(title.length == 0) {
      this.addTitleField();
    }
    else {
      title.forEach(element => {
        this.addTitleFieldValues(element.id, element.content);
      });
    }
    
  }

  private setObjectInfo() {
    const formValues = this.objectForm.getRawValue()
    this.connectServerService.postRequest<ApiResponse<null>>(Connect.urlServerLaraApi, 'updateObjectInfo', {
      infoObject: formValues,
      idarticle: this.idarticle,
    }).subscribe(
      (val: ApiResponse<null>) => {
        this.popupDialogService.popupDialog(val);
        this.getObjectInfo();
      }
    )
  }

  save() {
    this.submitted = true;
    if(this.objectForm.valid) {
      this.setObjectInfo();
    }
  }

  private logicForm() {
    // Ascolta i cambiamenti sul valore di definition_level1
    this.objectForm.get('definition_level1')?.valueChanges.subscribe(value => {
      if (value) {
        this.objectForm.get('definition_level2')?.setValue(null);
        this.getSelectOptions(2);
      } else {
        this.objectForm.get('definition_level2')?.disable(); // Disabilita definition_level2
        this.objectForm.get('definition_level3')?.disable(); // Disabilita definition_level3
      }
    });

    // Ascolta i cambiamenti sul valore di definition_level2
    this.objectForm.get('definition_level2')?.valueChanges.subscribe(value => {
      if (value) {
        this.objectForm.get('definition_level3')?.setValue(null);
        this.getSelectOptions(3);
      } else {
        this.objectForm.get('definition_level3')?.setValue(null);
        this.objectForm.get('definition_level3')?.disable(); // Disabilita definition_level3
      }
    });
  }

  private getSelectOptions(level: number) {
    let idFather = null;
    if (level == 2) {
      idFather = this.objectForm.get('definition_level1')?.value;
    }
    else {
      idFather = this.objectForm.get('definition_level2')?.value;
    }
    this.connectServerService.getRequest(Connect.urlServerLaraApi, "listAAObject", { level: level, idfather: idFather })
      .subscribe(
        (val: ApiResponse<{ listObject: SelectOptions[] }>) => {
          if (level == 2) {
            this.optionsLevel2 = val.data.listObject;
            if (this.optionsLevel2.length > 0) {
              this.objectForm.get('definition_level2')?.enable();
            }
            else {
              this.objectForm.get('definition_level2')?.disable();
            }
          }
          else if (level == 3) {
            this.optionsLevel3 = val.data.listObject;
            if (this.optionsLevel3.length > 0) {
              this.objectForm.get('definition_level3')?.enable();
            }
            else {
              this.objectForm.get('definition_level3')?.disable();
            }
          }
        }
      )
  }
}
