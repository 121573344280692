<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="row px-5 align-items-center color{{obj_card.id}}" (click)="navigate(obj_card.src)" (mouseenter)="changeCursor('pointer')"
             (mouseleave)="changeCursor('auto')" style="height: 240px; border-radius: 15px;">
                <div class="col-4 d-none d-sm-block">
                    <img style="max-width: 80px;" [src]="urlServerLaraApiMultimedia + obj_card.src">
                </div>
                <div class="col-12 col-sm-8 text-center py-2">
                    <h4 style="font-weight: bold; color: white;">{{obj_card.title}}</h4>
                    <div class="truncate_text bordered-text" style="color: white">{{obj_card.description}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
