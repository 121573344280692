<div class="container-fluid" style="min-height: 86%; min-width: 350px; background-color: #F2F2F2;">

    <div class="row justify-content-center align-items-center pt-3">
        <div class="col-12 col-md-1 text-start mb-1">
            <button class="btn btn-sm btn-outline-dark" (click)="goBack()">
                <i class="bi bi-arrow-left"></i>
            </button>
        </div>

        <div class="col-12 col-md-10 text-center mb-1">
            <span style="font-size: xx-large; font-weight: bold;">{{article?.generalForm?.title}}</span>
        </div>

        <div class="d-none d-md-block col-1">

        </div>
    </div>

    <div class="row ps-md-5 pe-md-5 p-0 p-sm-1 p-md-3 p-lg-3 p-xl-4 justify-content-center pagePadding rowWidth">
        <div class="col-12 order-1 order-md-2 col-lg-5">
            <div class="col-12 pb-lg-2 pt-2 pt-lg-0">
                <div class="card" style="height: 100%;">
                    <div class="card-header text-center" style="background-color: #1E73BE;">
                        <span style="font-weight: bold; color: white; font-size: large;">Informazioni Generali</span>
                    </div>
                    <div class="card-body">
                        <div class="innerTextFormat row justify-content-center">
                            <div class="col-12 mb-1" style="font-style: italic; color: gray;">
                                Codice Articolo: {{article?.generalForm?.code}}
                            </div>
                            <div class="col-12 mb-1">
                                <strong> Titolo: </strong> {{article?.generalForm?.title}}
                            </div>
                            <div *ngIf="article?.generalForm?.storage_place !== null && authService.isLoggedIn"
                                class="col-12 mb-1">
                                <strong> Luogo di conservazione: </strong> {{article?.generalForm?.storage_place}}
                            </div>
                            <div class="col-6 col-sm-3 col-lg-6 col-xxl-3 mb-1">
                                <strong> Piano: </strong> {{article?.generalForm?.floor}}
                            </div>
                            <div class="col-6 col-sm-3 col-lg-6 col-xxl-3 mb-1">
                                <strong> Stanza: </strong> {{article?.generalForm?.room}}
                            </div>
                            <div class="col-6 col-sm-3 col-lg-6 col-xxl-3 mb-1">
                                <strong> Scaffale: </strong> {{article?.generalForm?.rack}}
                            </div>
                            <div class="col-6 col-sm-3 col-lg-6 col-xxl-3 mb-1">
                                <strong> Contenitore: </strong> {{article?.generalForm?.case}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 pb-lg-2 pt-2 pt-lg-0">
                <div class="card" style="height: 100%;">
                    <div class="card-header text-center" style="background-color: #1E73BE;">
                        <span style="font-weight: bold; color: white; font-size: large;">Informazioni Oggetto e
                            Soggetto</span>
                    </div>
                    <div class="card-body">
                        <div class="innerTextFormat row justify-content-center">
                            <div class="col-12 text-center mb-1 pt-1">
                                <span class="subtitle">Oggetto</span>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
                                <strong> Definizione: </strong> {{article?.objectForm?.definition_level1}}
                            </div>
                            <div class="col-12 col-sm-6 col-lg-12  col-xxl-6 mb-1">
                                <strong> Tipologia: </strong> {{article?.objectForm?.definition_level2}}
                            </div>
                            <div class="col-12 col-sm-6 col-lg-12  col-xxl-6 mb-1">
                                <strong> Identificazione: </strong> {{article?.objectForm?.definition_level3}}
                            </div>
                            <div class="col-12 col-sm-6 col-lg-12  col-xxl-6 mb-1">
                                <strong> Titolo: </strong> {{article?.generalForm?.title}}
                            </div>
                            <div class="col-12 text-center mb-1 pt-1" style="border-top: 1px solid lightgray;">
                                <span class="subtitle">Quantità</span>
                            </div>
                            <div class="col-12 mb-1">
                                <strong> Numero: </strong> {{article?.objectForm?.quantity}}
                            </div>
                            <div *ngIf="article?.objectForm?.identification.length > 0 || article?.objectForm?.title.length > 0"
                                class="col-12 text-center mb-1 pt-1" style="border-top: 1px solid lightgray;">
                                <span class="subtitle">Soggetto</span>
                            </div>
                            <div *ngIf="article?.objectForm?.identification.length > 0" class="col-12 mb-1">
                                <strong> Identificazione: </strong>
                                <span *ngFor="let item of article?.objectForm?.identification" class="me-1">
                                    {{item.content}};
                                </span>
                            </div>
                            <div *ngIf="article?.objectForm?.title.length > 0" class="col-12 mb-1">
                                <strong> Titolo: </strong>
                                <span *ngFor="let item of article?.objectForm?.title" class="me-1">
                                    {{item.content}};
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div *ngIf="article?.description" class="d-none d-lg-block col-12 pb-lg-2 pt-2 pt-lg-0">
                <div class="card" style="height: 100%;">
                    <div style="background-color: #1E73BE;" class="card-header text-center">
                        <span style="font-weight: bold; color: white; font-size: large;">Descrizione</span>
                    </div>
                    <div class="card-body">
                        <div class="innerTextFormat">
                            <p class="text-center" style="font-size: medium; font-style: italic;">
                                {{article?.description}}
                            </p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="col-12 col-lg-7 ms-4 me-4 ms-lg-0 me-lg-0 pt-2 pt-lg-0 order-2 order-lg-1 text-center"
        style="min-height: 100%; padding-bottom: 0.5rem;">
            <div class="row align-items-center" id="carouselBody" style="min-height: 100%; margin-left: 0; margin-right: 0;">
                <div id="custCarousel" class="carousel slide col-12" data-ride="carousel">
                    <!-- slides -->
                    <div *ngFor="let img of files; let i = index" class="carousel-inner">
                        <div class="carousel-item" [class.active]="i === currentImg">
                            <img *ngIf="img.ext != 'pdf'" class="coverImg mt-4" [src]="urlServerLaraApiMultimedia + img.src"
                                matTooltip="Visualizza immagine a schermo intero" [title]="img.title"
                                (click)="openFullscreenModal(img)">
                            <img *ngIf="img.ext == 'pdf'" class="coverImg mt-4" src="assets/img/pdfCover.png"
                                matTooltip="Visualizza immagine a schermo intero" [title]="img.title"
                                (click)="openFullscreenModal(img)">
                        </div>
                    </div>

                    <a class="carousel-control-prev arrow" data-bs-target="#custCarousel" (click)="prevImg()">
                        <span class="carousel-control-prev-icon" style="filter: invert(1);"></span>
                    </a>
                    <a class="carousel-control-next arrow" data-bs-target="#custCarousel" (click)="nextImg()">
                        <span class="carousel-control-next-icon" style="filter: invert(1);"></span>
                    </a>

                    <!-- Container per le thumbnails con overflow -->
                    <div class="thumbnail-slider-wrapper">
                        <!-- Thumbnails -->
                        <ol class="carousel-indicators list-inline thumbList" id="thumbListScroll">
                            <li class="list-inline-item" *ngFor="let img of this.files; let i = index;"
                                [class.active]="i === currentImg">
                                <a id="carousel-selector-{{i}}" class="selected" data-target="#custCarousel">
                                    <img *ngIf="img.ext != 'pdf'" class="img-fluid modalImg"
                                        [src]="urlServerLaraApiMultimedia + img.src" [title]="img.title" (click)="goToImg(i)">
                                    <img *ngIf="img.ext == 'pdf'" src="assets/img/pdfThumbnail.png"
                                        class="img-fluid modalImgPdf" [title]="img.title" (click)="goToImg(i)">
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row ps-md-5 pe-md-5 p-0 ps-lg-3 pe-lg-3 ps-xl-4 pe-xl-4 justify-content-center pagePadding rowWidth">
        <!-- UBICAZIONE E DATI PATRIMONIALI -->
        <div class="col-12 col-lg-6 pb-lg-2 pt-2 pt-lg-0">
            <div class="card" style="height: 100%;">
                <div class="card-header text-center" style="background-color: #1E73BE;">
                    <span style="font-weight: bold; color: white; font-size: large;">Ubicazione e Dati
                        Patrimoniali</span>
                </div>
                <div class="card-body">
                    <div class="innerTextFormat row justify-content-center">
                        <div class="col-12 mb-1">
                            <strong> Ubicazione Originaria: </strong> {{article?.locationForm?.original_location}}
                        </div>
                    </div>
                    <div *ngFor="let item of article?.locationForm?.museumInventory; let i = index"
                        class="row innerTextFormat justify-content-center">
                        <div class="col-12 text-center mb-1 pt-1" style="border-top: 1px solid lightgray;">
                            <span class="subtitle">inventario di Museo o Soprintendenza {{i+1}}</span>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Numero: </strong> {{item.number}}
                        </div>
                        <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Data: </strong> {{item.date}}
                        </div>
                        <div class="col-12 mb-1">
                            <strong> Collocazione: </strong> {{item.location}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- CRONOLOGIA -->
        <div class="col-12 col-lg-6 pb-lg-2 pt-2 pt-lg-0">
            <div class="card" style="height: 100%;">
                <div class="card-header text-center" style="background-color: #1E73BE;">
                    <span style="font-weight: bold; color: white; font-size: large;">Cronologia</span>
                </div>
                <div class="card-body">
                    <div *ngFor="let item of article?.chronologyForm; let i = index"
                        class="row innerTextFormat justify-content-center"
                        [ngStyle]="{'border-top': i != 0 ? '1px solid lightgray' : 'none'}">
                        <div class="col-12 text-center mb-1 pt-1">
                            <span class="subtitle">Cronologia {{i+1}}</span>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Secolo: </strong> {{item.century}}
                        </div>
                        <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Frazione di Secolo: </strong> {{item.centuryFraction}}
                        </div>
                        <div class="col-6 col-md-3 col-lg-6 col-xl-6 col-xxl-3 mb-1">
                            <strong> Da: </strong> {{item.from}}
                        </div>
                        <div class="col-6 col-md-3 col-lg-6 col-xl-6 col-xxl-3 mb-1">
                            <strong> Validità: </strong> {{item.validity_from}}
                        </div>
                        <div class="col-6 col-md-3 col-lg-6 col-xl-6 col-xxl-3 mb-1">
                            <strong> A: </strong> {{item.to}}
                        </div>
                        <div class="col-6 col-md-3 col-lg-6 col-xl-6 col-xxl-3 mb-1">
                            <strong> Validità: </strong> {{item.validity_to}}
                        </div>
                        <div class="col-12 col-md-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Motivazione Cronologica: </strong> {{item.century}}
                        </div>
                        <div class="col-12 col-md-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Altre Datazioni: </strong> {{item.centuryFraction}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- DEFINIZIONE CULTURALE -->
        <div class="col-12 col-lg-6 pb-lg-2 pt-2 pt-lg-0">
            <div class="card" style="height: 100%;">
                <div class="card-header text-center" style="background-color: #1E73BE;">
                    <span style="font-weight: bold; color: white; font-size: large;">Definizione Culturale</span>
                </div>
                <div class="card-body">
                    <div *ngFor="let item of article?.culturalForm?.author; let i = index"
                        class="row innerTextFormat justify-content-center"
                        [ngStyle]="{'border-top': i != 0 ? '1px solid lightgray' : 'none'}">
                        <div class="col-12 text-center mb-1 pt-1">
                            <span class="subtitle">Autore {{i+1}}</span>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Riferimento all'Autore: </strong> {{item.authorRef}}
                        </div>
                        <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Riferimento all'Intervento: </strong> {{item.interventionRef}}
                        </div>
                        <div class="col-12 mb-1">
                            <strong> Motivazione dell'Attribuzione: </strong>
                            <span *ngFor="let element of item.attributions">
                                {{element.motivations}};
                            </span>
                        </div>
                    </div>

                    <div *ngFor="let item of article?.culturalForm?.scope; let i = index"
                        class="row innerTextFormat justify-content-center"
                        style="border-top: 1px solid lightgray">
                        <div class="col-12 text-center mb-1 pt-1">
                            <span class="subtitle">Ambito Culturale {{i+1}}</span>
                        </div>
                        <div class="col-12 col-md-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Denominazione: </strong> {{item.denomination}}
                        </div>
                        <div class="col-12 col-md-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Riferimento all'Intervento: </strong> {{item.interventionRef}}
                        </div>
                        <div class="col-12 mb-1">
                            <strong> Motivazione dell'Attribuzione: </strong>
                            <span *ngFor="let element of item.attributions">
                                {{element.motivations}};
                            </span>
                        </div>
                    </div>

                    <div class="row innerTextFormat justify-content-center"
                        style="border-top: 1px solid lightgray;">
                        <div class="col-12 mt-2">
                            <strong> Altre Attribuzioni: </strong>
                            <span *ngFor="let item of article?.culturalForm?.othersAttributions" class="me-1">
                                {{item.attributions}};
                            </span>
                        </div>
                    </div>

                    <div *ngFor="let item of article?.culturalForm?.client; let i = index"
                        class="row innerTextFormat justify-content-center"
                        style="border-top: 1px solid lightgray;">
                        <div class="col-12 text-center mb-1 pt-1">
                            <span class="subtitle">Committenza {{i+1}}</span>
                        </div>
                        <div class="col-12 mb-1">
                            <strong> Nome: </strong>
                            <span *ngFor="let element of item.names" class="me-1">
                                {{element.name}};
                            </span>
                        </div>
                        <div class="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4 mb-1">
                            <strong> Data: </strong> {{item.date}}
                        </div>
                        <div class="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4 mb-1">
                            <strong> Circostanza: </strong> {{item.circumstance}}
                        </div>
                        <div class="col-12 col-xxl-4 mb-1">
                            <strong> Fonte: </strong> {{item.source}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- DATI TECNICI -->
        <!-- <div class="col-12 col-lg-6 pb-lg-2 pt-2 pt-lg-0">
            <div class="card" style="height: 100%;">
                <div class="card-header text-center" style="background-color: #1E73BE;">
                    <span style="font-weight: bold; color: white; font-size: large;">Dati Tecnici</span>
                </div>
                <div class="card-body">
                    <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
                        <strong> Riferimento all'Autore: </strong> {{item.authorRef}}
                    </div>
                    <div *ngFor="let item of article?.culturalForm?.author; let i = index"
                        class="row innerTextFormat justify-content-center"
                        [ngStyle]="{'border-top': i != 0 ? '1px solid lightgray' : 'none'}">
                        <div class="col-12 text-center mb-1 pt-1">
                            <span class="subtitle">Autore {{i+1}}</span>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Riferimento all'Autore: </strong> {{item.authorRef}}
                        </div>
                        <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Riferimento all'Intervento: </strong> {{item.interventionRef}}
                        </div>
                        <div class="col-12 mb-1">
                            <strong> Motivazione dell'Attribuzione: </strong>
                            <span *ngFor="let element of item.attributions">
                                {{element.motivations}};
                            </span>
                        </div>
                    </div>

                    <div *ngFor="let item of article?.culturalForm?.scope; let i = index"
                        class="row innerTextFormat justify-content-center"
                        style="border-top: 1px solid lightgray">
                        <div class="col-12 text-center mb-1 pt-1">
                            <span class="subtitle">Ambito Culturale {{i+1}}</span>
                        </div>
                        <div class="col-12 col-md-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Denominazione: </strong> {{item.denomination}}
                        </div>
                        <div class="col-12 col-md-6 col-lg-12 col-xxl-6 mb-1">
                            <strong> Riferimento all'Intervento: </strong> {{item.interventionRef}}
                        </div>
                        <div class="col-12 mb-1">
                            <strong> Motivazione dell'Attribuzione: </strong>
                            <span *ngFor="let element of item.attributions">
                                {{element.motivations}};
                            </span>
                        </div>
                    </div>

                    <div class="row innerTextFormat justify-content-center"
                        style="border-top: 1px solid lightgray;">
                        <div class="col-12 mt-2">
                            <strong> Altre Attribuzioni: </strong>
                            <span *ngFor="let item of article?.culturalForm?.othersAttributions" class="me-1">
                                {{item.attributions}};
                            </span>
                        </div>
                    </div>

                    <div *ngFor="let item of article?.culturalForm?.client; let i = index"
                        class="row innerTextFormat justify-content-center"
                        style="border-top: 1px solid lightgray;">
                        <div class="col-12 text-center mb-1 pt-1">
                            <span class="subtitle">Committenza {{i+1}}</span>
                        </div>
                        <div class="col-12 mb-1">
                            <strong> Nome: </strong>
                            <span *ngFor="let element of item.names" class="me-1">
                                {{element.name}};
                            </span>
                        </div>
                        <div class="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4 mb-1">
                            <strong> Data: </strong> {{item.date}}
                        </div>
                        <div class="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4 mb-1">
                            <strong> Circostanza: </strong> {{item.circumstance}}
                        </div>
                        <div class="col-12 col-xxl-4 mb-1">
                            <strong> Fonte: </strong> {{item.source}}
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>

    <!-- MODIFICA SCHEDA ED ELIMINA -->
    <div *ngIf="authService.isLoggedIn(); else notLoggedBlock" class="row text-center justify-content-center mt-4 pb-4">
        <div class="col-12 col-sm-6 col-md-4 col-xl-2">
            <button class="btn btn-warning mt-2" [routerLink]="['/modificaSchedaArtistica', article?.generalForm?.id]"
                style="width: 170px;">
                <i class="bi bi-pencil"></i>
                Modifica Scheda
            </button>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-xl-2">
            <button class="btn btn-danger mt-2" (click)="deleteArticle(article?.generalForm?.id!)"
                style="width: 170px;">
                <i class="bi bi-trash"></i>
                Elimina
            </button>
        </div>
    </div>

    <ng-template #notLoggedBlock>
        <div *ngIf="false" class="row text-center justify-content-center mt-4 pb-4">
            <div class="col-12 col-md-6 col-xl-4">
                <button class="btn btn-sm btn-outline-success mt-2" [routerLink]="['/richiediAccesso']">
                    <i class="bi bi-person"></i>
                    Richiedi Visualizzazione Completa
                </button>
            </div>
        </div>
    </ng-template>
</div>
