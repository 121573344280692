import { Component, inject } from '@angular/core';
import { GeneralInfoComponent } from "../article-artistic-cards/general-info/general-info.component";
import { Router } from '@angular/router';
import { ConnectServerService } from '../../../services/connect-server.service';
import { Connect } from '../../../classes/connect';
import { ApiResponse } from '../../../interfaces/api-response';
import { SelectOptions } from '../interfaces/select-options';
import { PopupDialogService } from '../../../services/popup-dialog.service';

@Component({
  selector: 'app-article-artistic-insert',
  standalone: true,
  imports: [
    GeneralInfoComponent
  ],
  templateUrl: './article-artistic-insert.component.html',
  styleUrl: './article-artistic-insert.component.scss'
})
export class ArticleArtisticInsertComponent {
  readonly connectServerService = inject(ConnectServerService);
  readonly popupDialogService = inject(PopupDialogService);
  constructor(private router: Router) {
    this.getArticleType();
  }

  listType: SelectOptions[] = [];

  onButtonClicked(event: any) {
    if (Object.keys(event).length > 0) {
      this.connectServerService.postRequest<ApiResponse<{ id: number }>>(Connect.urlServerLaraApi, 'insertArticleArtistic',
        event
      ).subscribe(
        (esito: ApiResponse<{ id: number }>) => {
          this.popupDialogService.popupDialog(esito);
          this.router.navigate(["modificaSchedaArtistica", esito.data.id])
        }
      );
    }
  }

  private getArticleType() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'listAAType', {})
      .subscribe(
        (val: ApiResponse<{ listType: SelectOptions[] }>) => {
          this.listType = val.data.listType;
        }
      )
  }

}
