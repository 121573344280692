import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectCulturalDefinition } from '../../interfaces/select-cultural-definition';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { CheckFormValidityService } from '../../../../services/check-form-validity.service';
import { PopupDialogService } from '../../../../services/popup-dialog.service';
import { Connect } from '../../../../classes/connect';
import { ApiResponse } from '../../../../interfaces/api-response';

interface Author {
  id: number;
  authorRef: number | null;
  interventionRef: number | null;
  attributions: { id: number, motivations: string | null }[];
}

interface Scope {
  id: number;
  denomination: number | null;
  interventionRef: number | null;
  attributions: { id: number, motivations: string | null }[];
}

interface OthersAttributions {
  id: number;
  attributions: string;
}

interface Client {
  id: number;
  names: { id: number, name: string }[];
  date: string | null;
  circumstance: string | null;
  source: string | null;
}

@Component({
  selector: 'app-cultural-definition',
  standalone: true,
  imports: [
    MatExpansionModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './cultural-definition.component.html',
  styleUrl: './cultural-definition.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CulturalDefinitionComponent {

  readonly panelOpenState = signal(false);

  @Input() idarticle: number = 0;
  @Input() listCulturalDefinition: SelectCulturalDefinition | null = null;

  saved: boolean = false;
  submitted: boolean = false;

  culturalForm: FormGroup;

  constructor(private fb: FormBuilder, private connectServerService: ConnectServerService,
    private popupDialogService: PopupDialogService, private checkFormValidityService: CheckFormValidityService) {
    this.culturalForm = this.fb.group({
      author: this.fb.array([this.createAuthor()]),
      scope: this.fb.array([this.createScope()]),
      othersAttributions: this.fb.array([this.createOthersAttributions()]),
      client: this.fb.array([this.createClient()])
    });
  }

  ngOnInit(): void {
    this.getCulturalInfo();
  }

  // Funzione che crea un singolo gruppo di campi
  createAuthor(): FormGroup {
    return this.fb.group({
      id: [0],
      authorRef: [null, Validators.required],
      interventionRef: [null, Validators.required],
      attributions: this.fb.array([this.createAttribution()]),
    });
  }

  createAuthorValues(content: Author): FormGroup {
    return this.fb.group({
      id: [content.id],
      authorRef: [content.authorRef, Validators.required],
      interventionRef: [content.interventionRef, Validators.required],
      attributions: this.addAttributionsValues(content.attributions)
      //attributions: this.createAttributionValues(content.attributions),
    });
  }

  createScope(): FormGroup {
    return this.fb.group({
      id: [0],
      denomination: [null, Validators.required],
      interventionRef: [null],
      attributions: this.fb.array([this.createAttribution()]),
    })
  }

  createScopeValues(scope: Scope): FormGroup {
    return this.fb.group({
      id: [scope.id],
      denomination: [scope.denomination, Validators.required],
      interventionRef: [scope.interventionRef],
      attributions: this.addAttributionsValues(scope.attributions),
    })
  }

  createAttribution(): FormGroup {
    return this.fb.group({
      id: [0],
      motivations: [null, Validators.required],
    })
  }

  createAttributionValues(attribution: { id: number, motivations: string | null }): FormGroup {
    return this.fb.group({
        id: [attribution.id, Validators.required],
        motivations: [attribution.motivations, Validators.required]
      })
  }

  createOthersAttributions(): FormGroup {
    return this.fb.group({
      id: [0],
      attributions: [null, Validators.maxLength(70)]
    })
  }

  createOthersAttributionsValues(content: OthersAttributions): FormGroup {
    return this.fb.group({
      id: [content.id],
      attributions: [content.attributions, Validators.maxLength(70)]
    })
  }

  createClient(): FormGroup {
    return this.fb.group({
      id: [0],
      names: this.fb.array([this.createName()]),
      date: [null, Validators.maxLength(50)],
      circumstance: [null, Validators.maxLength(100)],
      source: [null, Validators.maxLength(50)]
    })
  }

  createClientValues(client: Client): FormGroup {
    return this.fb.group({
      id: [client.id],
      names: this.createNameValues(client.names),
      date: [client.date, Validators.maxLength(50)],
      circumstance: [client.circumstance, Validators.maxLength(100)],
      source: [client.source, Validators.maxLength(50)]
    })
  }

  createName(): FormGroup {
    return this.fb.group({
      id: [0],
      name: [null, Validators.maxLength(70)]
    })
  }

  createNameValues(names: { id: number, name: string | null }[]): FormArray {
    return this.fb.array(names.map(item =>
      this.fb.group({
        id: [item.id],
        name: [item.name, Validators.maxLength(70)]
      })
    ));
  }

  // Restituisce il FormArray per una gestione più semplice
  get author(): FormArray {
    return this.culturalForm.get('author') as FormArray;
  }

  get scope(): FormArray {
    return this.culturalForm.get('scope') as FormArray;
  }

  get othersAttributions(): FormArray {
    return this.culturalForm.get('othersAttributions') as FormArray;
  }

  get client(): FormArray {
    return this.culturalForm.get('client') as FormArray;
  }

  // Aggiunge un nuovo gruppo di campi al FormArray
  addAuthor() {
    this.author.push(this.createAuthor());
  }

  addAuthorValues(author: Author) {
    this.author.push(this.createAuthorValues(author));
  }

  addScope() {
    this.scope.push(this.createScope());
  }

  addScopeValues(scope: Scope) {
    this.scope.push(this.createScopeValues(scope));
  }

  addClient() {
    this.client.push(this.createClient());
  }

  addClientValues(client: Client) {
    this.client.push(this.createClientValues(client));
  }

  addOthersAttributions() {
    this.othersAttributions.push(this.createOthersAttributions());
  }

  addOthersAttributionsValues(otherAttributions: OthersAttributions) {
    this.othersAttributions.push(this.createOthersAttributionsValues(otherAttributions));
  }

  addAttributionsScope(index: number) {
    const reasonsArray = this.scope.at(index).get('attributions') as FormArray;
    reasonsArray.push(this.createAttribution());
  }

  addAttributionsAuthor(index: number) {
    const reasonsArray = this.author.at(index).get('attributions') as FormArray;
    reasonsArray.push(this.createAttribution());
  }

  addAttributionsValues(content: {id: number, motivations: string | null}[]): FormArray {
    const reasonsArray = this.fb.array([]) as FormArray;
    if(content.length > 0) {
      content.forEach(item => {
        reasonsArray.push(this.createAttributionValues(item));
      });
    }
    else {
      reasonsArray.push(this.createAttribution());
    }
    
    return reasonsArray;
  }

  addName(index: number) {
    const nameArray = this.client.at(index).get('names') as FormArray;
    nameArray.push(this.createName());
  }

  // Rimuove un gruppo di campi dal FormArray, lasciando sempre almeno uno
  removeAuthor(index: number) {
    if (this.author.length > 1) {
      this.author.removeAt(index);
    }
  }

  removeScope(index: number) {
    if (this.scope.length > 1) {
      this.scope.removeAt(index);
    }
  }

  removeClient(index: number) {
    if (this.client.length > 1) {
      this.client.removeAt(index);
    }
  }

  removeOthersAttributions(index: number) {
    if (this.othersAttributions.length > 1) {
      this.othersAttributions.removeAt(index);
    }
  }

  removeAttributionScope(index: number, attributionIndex: number) {
    const reasonsArray = this.scope.at(index).get('attributions') as FormArray;
    if (reasonsArray.length > 1) {
      reasonsArray.removeAt(attributionIndex);
    }
  }

  removeAttributionAuthor(index: number, attributionIndex: number) {
    const reasonsArray = this.author.at(index).get('attributions') as FormArray;
    if (reasonsArray.length > 1) {
      reasonsArray.removeAt(attributionIndex);
    }
  }

  removeName(index: number, nameIndex: number) {
    const nameArray = this.client.at(index).get('names') as FormArray;
    if (nameArray.length > 1) {
      nameArray.removeAt(nameIndex);
    }
  }

  // Funzione per ottenere il FormArray 'attributions' per un chronology specifico
  getScopeControls(index: number): FormArray {
    return this.scope.at(index).get('attributions') as FormArray;
  }

  getAuthorControls(index: number): FormArray {
    return this.author.at(index).get('attributions') as FormArray;
  }

  getClientControls(index: number): FormArray {
    return this.client.at(index).get('names') as FormArray;
  }

  private getCulturalInfo() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'culturalDefinitionInfo',
      { idarticle: this.idarticle })
      .subscribe(
        (val: ApiResponse<{ culturalForm: any }>) => {
          this.author.clear();
          this.scope.clear();
          this.othersAttributions.clear();
          this.client.clear();
          this.createNestedFields(val.data.culturalForm);
          this.saved = this.checkFormValidityService.areAllRequiredFieldsFilled(this.culturalForm);
          if(this.saved) {
            this.panelOpenState.set(false);
          }
          else {
            this.panelOpenState.set(true);
          }
        }
      )
  }

  private createNestedFields(culturalForm: {
    author: Author[],
    scope: Scope[],
    othersAttributions: OthersAttributions[],
    client: Client[]
  }) {
    if (culturalForm.author.length == 0) {
      this.addAuthor();
    }
    else {
      culturalForm.author.forEach(element => {
        this.addAuthorValues(element);
      });
    }

    if (culturalForm.scope.length == 0) {
      this.addScope();
    }
    else {
      culturalForm.scope.forEach(element => {
        this.addScopeValues(element);
      });
    }

    if (culturalForm.othersAttributions.length == 0) {
      this.addOthersAttributions();
    }
    else {
      culturalForm.othersAttributions.forEach(element => {
        this.addOthersAttributionsValues(element);
      });
    }

    if (culturalForm.client.length == 0) {
      this.addClient();
    }
    else {
      culturalForm.client.forEach(element => {
        this.addClientValues(element);
      });
    }
  }

  private setCulturalInfo() {
    const formValues = this.culturalForm.getRawValue();
    console.log(formValues);
    this.connectServerService.postRequest(Connect.urlServerLaraApi, 'updateCulturalDefinitionInfo',
      { idarticle: this.idarticle, infoCulturalDefinition: {author: formValues.author, client: formValues.client, 
        othersAttributions: formValues.othersAttributions, scope: formValues.scope} })
      .subscribe(
        (val: ApiResponse<null>) => {
          this.popupDialogService.popupDialog(val);
          this.getCulturalInfo();
        }
      )
  }

  save() {
    this.submitted = true;
    if(this.culturalForm.valid) {
      this.setCulturalInfo();
    }
  }

}
