<div class="container-fluid sticky-header">
    <div class="row justify-content-end align-items-center mt-2 borderBottom">
        <div class="col-2">

        </div>
        <div class="col-8 text-center">
            <span style="font-size: x-large; font-weight: bold;">
                Modifica Visualizzazione Schede Homepage
            </span>
        </div>
        <div class="col-2 text-end mb-2">
            <button class="btn btn-outline-dark" (click)="closeDialog()">
                <i class="bi bi-x"></i>
            </button>
        </div>
    </div>
</div>

<div class="container-fluid">
    <!-- CARD  -->
    <div class="row justify-content-center align-items-center p-1 p-md-2 p-lg-3 mt-0 mt-xxl-4" style="cursor: default;">
        <div *ngFor="let card of cards" class="col-12 col-md-6 col-xxl-3 mt-1" style="position: relative">
            <div *ngIf="card.id != 0" class="trashIcon">
                <i class="bi bi-lg bi-trash fs-4" (click)="removeCard()"></i>
            </div>
            <app-card *ngIf="card.id != 0; else emptyBlock" [obj_card]="card"></app-card>
            <ng-template #emptyBlock>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <div class="row px-5 align-items-center justify-content-center"
                                style="height: 240px; border: 1px solid black; border-radius: 15px;">
                                SPAZIO LIBERO PER NUOVA SCHEDA
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

    <!-- SELEZIONE CARD -->
    <form [formGroup]="cardsForm">
        <div class="row justify-content-center align-items-center p-1 p-md-2 p-lg-3 mt-0 mt-xxl-4">
            <div class="col-12 col-md-6 col-xxl-3">
                <label for="card1">Prima Card</label>
                <div class="input-group mb-3" style="height: 50px;">
                    <input type="text" matInput [matAutocomplete]="card1" class="form-control" placeholder="Titolo card"
                        id="card1" formControlName="card1"
                        [class.is-invalid]="cardsForm.get('card1')?.invalid &&
                                  (cardsForm.get('card1')?.dirty || cardsForm.get('card1')?.touched) && cardsForm.get('card1')?.value !== null">
                </div>
                <mat-autocomplete #card1="matAutocomplete" [displayWith]="displayArticleName">
                    <mat-option *ngFor="let option of filteredArticles$[0] | async" [value]="option">
                        <div>
                            {{option.title}}
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>

            <div class="col-12 col-md-6 col-xxl-3">
                <label for="card2">Seconda Card</label>
                <div class="input-group mb-3" style="height: 50px;">
                    <input type="text" matInput [matAutocomplete]="card2" class="form-control" placeholder="Titolo card"
                        id="card2" formControlName="card2"
                        [class.is-invalid]="cardsForm.get('card2')?.invalid &&
                                  (cardsForm.get('card2')?.dirty || cardsForm.get('card2')?.touched) && cardsForm.get('card2')?.value !== null">
                </div>
                <mat-autocomplete #card2="matAutocomplete" [displayWith]="displayArticleName">
                    <mat-option *ngFor="let option of filteredArticles$[1] | async" [value]="option">
                        <div>
                            {{option.title}}
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>

            <div class="col-12 col-md-6 col-xxl-3">
                <label for="card1">Terza Card</label>
                <div class="input-group mb-3" style="height: 50px;">
                    <input type="text" matInput [matAutocomplete]="card3" class="form-control" placeholder="Titolo card"
                        id="card1" formControlName="card3"
                        [class.is-invalid]="cardsForm.get('card3')?.invalid &&
                                  (cardsForm.get('card3')?.dirty || cardsForm.get('card3')?.touched) && cardsForm.get('card3')?.value !== null">
                </div>
                <mat-autocomplete #card3="matAutocomplete" [displayWith]="displayArticleName">
                    <mat-option *ngFor="let option of filteredArticles$[2] | async" [value]="option">
                        <div>
                            {{option.title}}
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>

            <div class="col-12 col-md-6 col-xxl-3">
                <label for="card1">Quarta Card</label>
                <div class="input-group mb-3" style="height: 50px;">
                    <input type="text" matInput [matAutocomplete]="card4" class="form-control" placeholder="Titolo card"
                        id="card4" formControlName="card4"
                        [class.is-invalid]="cardsForm.get('card4')?.invalid &&
                                  (cardsForm.get('card4')?.dirty || cardsForm.get('card4')?.touched) && cardsForm.get('card4')?.value !== null">
                </div>
                <mat-autocomplete #card4="matAutocomplete" [displayWith]="displayArticleName">
                    <mat-option *ngFor="let option of filteredArticles$[3] | async" [value]="option">
                        <div>
                            {{option.title}}
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </form>
</div>
