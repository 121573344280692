<div class="container-fluid p-0 d-flex" style="min-width: 350px; min-height: 80%; max-width: 2000px;">
    <div class="bg-light flex-fill">
        <div class="ps-4 pe-2 pb-4 mb-2 pt-1 pt-md-2 pt-lg-3 row align-items-center justify-content-center"
            style="width: 100%;">
            <div class="d-none d-md-block col-md-4">
            </div>
            <div class="col-12 text-center mb-3 mt-1">
                <span class="ps-2 pt-1 pb-1 pe-2"
                    style="font-size: x-large; font-weight: 500; background-color: #1E73BE; border-radius: 10px; color: white;">
                    Archivio Artistico
                </span>
            </div>

            <form [formGroup]="filterForm">
                <div class="row justify-content-center align-items-center">
                    <div class="col-12 col-md-8 col-lg-6 mb-4 text-center">
                        <h5 style="font-weight: bold; padding: 3px">RICERCA TESTUALE</h5>
                        <div class="input-group mb-3" style="height: 50px;">
                            <input type="text" class="form-control" placeholder="Cosa stai cercando?"
                                (keydown.enter)="searchArticles()" id="title" formControlName="title">
                            <button class="btn btn-outline-dark searchButton" (click)="searchArticles()" type="button"
                                id="button-addon2">Cerca</button>
                        </div>
                    </div>
                </div>
            </form>

            <div class="col-12 col-sm-6 col-md-4 text-center">
                <span class="subtitles" style="font-weight: bold;">Risultati totali: </span>
                {{totalResults}}
            </div>
            <div class="col-12 col-sm-6 col-md-4 text-center">
                <span class="subtitles" style="font-weight: bold;">Risultati per pagina: </span>
                {{itemsPerPage}}
            </div>
            <div class="d-none d-md-block col-md-4 text-center">
                <span class="subtitles" style="font-weight: bold;">Pagine totali: </span>
                {{totalPages}}
            </div>
            <hr>
            <div class="container-fluid" style="max-width: 1100px;">
                <div class="row justify-content-center">
                    <div *ngIf="articles.length > 0; else noContentBlock" class="col-12">
                        <article *ngFor="let content of articles" class="postcard dark yellow ms-0 me-0">
                            <div class="postcard__img_link">
                                <img class="postcard__img" [src]="urlServerLaraApiMultimedia + content.src" alt="Image Title">
                            </div>
                            <div class="postcard__text">
                                <h1 class="postcard__title green">
                                    <div class="titleLink" (click)="navigate(content.id)">{{content.title}}</div>
                                </h1>
                                <!-- <div class="postcard__subtitle small">
                                    <time datetime="2020-05-25 12:00:00">
                                        <i class="fas fa-calendar-alt mr-2"></i>{{content.category.title}}
                                    </time>
                                </div> -->
                                <div class="postcard__bar"></div>
                                <div class="postcard__preview-txt">{{content.description}}</div>
                                <div class="postcard__tagbox mt-4 align-self-center">
                                    <span class="tag__item" (click)="navigate(content.id)">
                                        <i class="fas fa-tag mr-2">
                                        </i>Vedi Articolo
                                    </span>
                                </div>
                            </div>
                        </article>
                    </div>
                    <ng-template #noContentBlock>
                        <div class="col-12 text-center mt-5">
                            <span style="font-size: large; font-weight: bold;">La ricerca non ha prodotto
                                risultati</span>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf="totalPages > 1" class="row justify-content-center align-items-center">
            <div class="col-12 text-center paginatorPosition">
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-center mb-1">
                        <li class="page-item">
                            <a class="page-link" href="javascript:void(0)" aria-label="Previous"
                                (click)="previousPage()">
                                <span aria-hidden="true" style="color: #1E73BE;">&laquo;</span>
                            </a>
                        </li>
                        <li class="page-item pageSelector" *ngFor="let i of pages"
                            [class.active]="currentPageIndex == i">
                            <a class="page-link pageSelector" (click)="setCurrentPage(i)">{{i}}</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="javascript:void(0)" aria-label="Next" (click)="nextPage()">
                                <span aria-hidden="true" style="color: #1E73BE;">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
