import { Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CardComponent } from "../../../../components/card/card.component";
import { Card } from '../../../../interfaces/home';
import { CommonModule } from '@angular/common';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, filter, map, Observable, startWith, switchMap } from 'rxjs';
import { SearchAutocompleteService } from '../../../../services/search-autocomplete.service';
import { Article } from '../../../../interfaces/article';

@Component({
  selector: 'app-card-view-popup',
  standalone: true,
  imports: [
    CardComponent,
    CommonModule,
    MatAutocompleteModule,
    ReactiveFormsModule
  ],
  templateUrl: './card-view-popup.component.html',
  styleUrl: './card-view-popup.component.scss'
})
export class CardViewPopupComponent implements OnInit {

  cards: Card[] = [];
  filteredArticles$: Observable<Article[]>[] = [];

  cardsForm = new FormGroup({
    card1: new FormControl<Card | null>(null),
    card2: new FormControl<Card | null>(null),
    card3: new FormControl<Card | null>(null),
    card4: new FormControl<Card | null>(null),
  })

  private readonly searchAutocompleteService = inject(SearchAutocompleteService);
  constructor(@Inject(MAT_DIALOG_DATA) public data: { cards: Card[] },
    private dialogRef: MatDialogRef<CardViewPopupComponent>,) {
    // this.cards = data.cards;
    // this.cards[2].id = 0;
    // this.cardsForm.get('card1')?.setValue(this.cards[0]);
    // this.cardsForm.get('card2')?.setValue(this.cards[1]);
    // this.cardsForm.get('card3')?.setValue(this.cards[2]);
    // this.cardsForm.get('card4')?.setValue(this.cards[3]);
  }

  ngOnInit(): void {
    this.searchArticles();
  }

  removeCard() {

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private searchArticles() {
    // ricerca
    const cardNames = ['card1', 'card2', 'card3', 'card4'];

    // const card1 = this.cardsForm.get('card1');
    // if (card1) {
    //   this.filteredArticles$ = card1.valueChanges
    //     .pipe(
    //       startWith(''),
    //       map(value => typeof value === 'string' ? value : value?.name || ''),
    //       filter(value => value.length > 0),
    //       debounceTime(600),
    //       switchMap((value: string) =>
    //         value ? this.searchAutocompleteService.getArticles(value) : [])
    //     );
    // }
    cardNames.forEach((cardName, index) => {
      const cardControl = this.cardsForm.get(cardName);

      if (cardControl) {
        // Configura il filtro per ciascun controllo e salva l'observable in filteredArticles$
        this.filteredArticles$[index] = cardControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value?.name || ''),
          filter(value => value.length > 0),
          debounceTime(600),
          switchMap((value: string) =>
            value ? this.searchAutocompleteService.getArticles(value) : [])
        );
      }
    });
  }
  displayArticleName(article?: Article): string {
    return article ? article.title : '';
  }

}
