import { Injectable } from '@angular/core';
import { Article } from '../interfaces/article';
import { ConnectServerService } from './connect-server.service';
import { Connect } from '../classes/connect';
import { ApiResponse } from '../interfaces/api-response';
import { ArticleFile } from '../interfaces/image';
import { Observable } from 'rxjs';
import { Filters } from '../interfaces/filters';
import { ArticleArtisticGeneral, ArticleArtisticICCD } from '../interfaces/article-artistic';

@Injectable({
  providedIn: 'root'
})

export class ArticlesService {

  articlesInfo: { category: string, city: string, address: string, id: number }[] = [];
  articlesData: { dimension: string, imgFormat: string, weigth: number, id: number }[] = [];
  itemsPerPage = 10;
  currentPage = 1;
  //filteredArticles: Article[] = [];

  getArticleInfo(id1: number): { category: string, city: string, address: string, id: number } {
    const foundArticle = this.articlesInfo.find(article => article.id == id1);
    if (foundArticle != undefined) {
      return foundArticle;
    }
    else return { category: 'not found', city: 'not found', address: 'not found', id: 0 };
  }

  getArticleData(id1: number): { dimension: string, imgFormat: string, weigth: number, id: number } {
    const foundArticle = this.articlesData.find(article => article.id == id1);
    if (foundArticle != undefined) {
      return foundArticle;
    }
    else return { dimension: 'not found', imgFormat: 'not found', weigth: 0, id: 0 };
  }

  // filterArticles(text: string = '') {
  //   if (text != '') {
  //     this.filteredArticles = [];
  //     this.filteredArticles = this.articles.filter(article => article.title.replace(/\s/g, '').toLowerCase().includes(text.replace(/\s/g, '').toLowerCase()));
  //   }
  //   else {
  //     this.filteredArticles = [];
  //     this.filteredArticles = this.articles;
  //   }

  // }

  getCoverIndex(): number {
    return 0;
  }

  completeFilter(category: any[], place: string, years: any[], specificPlace: string) {

    console.log("Luogo d'interesse: ", specificPlace);
    console.log("atti: ", category[0].value);
    console.log("contabilità: ", category[1].value);
    console.log("place: ", place);
    console.log("from year: ", years[0].value, " to year: ", years[1].value);


  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  // getOptions() {
  //   const places: any[] = [
  //     'Borgo San Lorenzo', 'Vicchio', 'Scarperia', 'San Piero'
  //   ];
  //   return places;
  // }

  // findArticleIndex(id: number) {
  //   const articleIndex = this.articles.findIndex(article => article.id === id);
  //   if(articleIndex - 1 >= 0) {
  //     this.prevId = this.articles[articleIndex-1].id
  //   } else {
  //     this.prevId = null;
  //   }
  //   if(articleIndex + 1 <= this.articles.length) {
  //     this.nextId = this.articles[articleIndex+1].id;
  //   } else {
  //     this.nextId = null;
  //   }
  // }

  getFilesServer(article_id: number): Observable<ApiResponse<{listFiles: ArticleFile[]}>> {
    return this.connectService.getRequest<ApiResponse<{listFiles: ArticleFile[]}>>(Connect.urlServerLaraApi, 'listFilesArticle', {id: article_id})
  }

  getFilesFromImage(img_id: number): Observable<ApiResponse<{listFilesFromImage: ArticleFile[]}>> {
    return this.connectService.getRequest<ApiResponse<{listFilesFromImage: ArticleFile[]}>>(Connect.urlServerLaraApi, 'listFilesArticleFromImage', {id: img_id});
  }

  getArticlesFromServer(itemsPerPage: number, currentPageIndex: number, type: number,
    query: Filters | string | null | undefined): Observable<ApiResponse<{listArticles: Article[], totalPages: number, totalResults: number}>>
  {
    return this.connectService.postRequest<ApiResponse<
      { listArticles: Article[], totalPages: number, totalResults: number }>>
      (Connect.urlServerLaraApi, 'listArticlesHistoricalRead',
      {
        itemsPerPage: itemsPerPage,
        currentPageIndex: currentPageIndex,
        search_type: type,
        query: query
      })
  }

  getArticlesArtisticFromServer(itemsPerPage: number, currentPageIndex: number,
    query: string): Observable<ApiResponse<{listArticles: ArticleArtisticGeneral[], totalPages: number, totalResults: number}>>
  {
    return this.connectService.postRequest<ApiResponse<{listArticles: ArticleArtisticGeneral[], totalPages: number, totalResults: number}>>
      (Connect.urlServerLaraApi, 'listArticlesArtisticRead',
      {
        itemsPerPage: itemsPerPage,
        currentPageIndex: currentPageIndex,
        query: query
      })
  }

  getArticleFromServer(id: number): Observable<ApiResponse<{ article: Article }>> {
    return this.connectService.getRequest<ApiResponse<{ article: Article }>>(Connect.urlServerLaraApi, 'infoArticleHistoricalRead', { id: id })
  }

  getArticleInfoServer(idarticle: number): Observable<ApiResponse<{ article: Article, listFiles: ArticleFile[] }>> {
    return this.connectService.getRequest<ApiResponse<{ article: Article }>>(Connect.urlServerLaraApi, 'infoArticleHistorical', { id: idarticle })
  }

  getArticleArtisticFromServer(id: number): Observable<ApiResponse<{ article: ArticleArtisticICCD }>> {
    return this.connectService.getRequest<ApiResponse<{ article: ArticleArtisticICCD }>>(Connect.urlServerLaraApi, 'infoArticleArtisticRead', { id: id })
  }

  getArticleArtisticInfoServer(idarticle: number): Observable<ApiResponse<{ article: ArticleArtisticICCD[], listFiles: ArticleFile[] }>> {
    return this.connectService.getRequest<ApiResponse<{ article: ArticleArtisticICCD[] }>>(Connect.urlServerLaraApi, 'infoArticleArtistic', { id: idarticle })
  }

  constructor(private connectService: ConnectServerService) {

    this.articlesData = [
      { dimension: '1080 x 800', imgFormat: 'jpeg', weigth: 50, id: 1 },
      { dimension: '1000 x 670', imgFormat: 'png', weigth: 34, id: 2 },
      { dimension: '870 x 230', imgFormat: 'gif', weigth: 10, id: 3 },
      { dimension: '2040 x 1080', imgFormat: 'tif', weigth: 500, id: 4 },
      { dimension: '2000 x 1900', imgFormat: 'heic', weigth: 394, id: 5 },
      { dimension: '576 x 780', imgFormat: 'jpeg', weigth: 120, id: 6 },
    ]

  }
}
