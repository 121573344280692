import { Component, OnInit, ViewChild } from '@angular/core';
import { Category, City, SubCategory } from '../../interfaces/article';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConnectServerService } from '../../services/connect-server.service';
import { Connect } from '../../classes/connect';
import { ApiResponse } from '../../interfaces/api-response';
import { CommonModule } from '@angular/common';
import { TreeViewComponent } from "./tree-view/tree-view.component";
import { PopupDialogService } from '../../services/popup-dialog.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { debounceTime, filter, map, Observable, startWith, switchMap } from 'rxjs';
import { cityValidator } from '../../validators/city-validator';
import { SearchAutocompleteService } from '../../services/search-autocomplete.service';

@Component({
  selector: 'app-import-articles-multiple',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TreeViewComponent,
    MatAutocompleteModule
  ],
  templateUrl: './import-articles-multiple.component.html',
  styleUrl: './import-articles-multiple.component.scss'
})
export class ImportArticlesMultipleComponent implements OnInit {
  categoryOptions: Category[] = [];
  subcategoryOptions: SubCategory[] = [];
  folders: string[] = [];
  year = new Date().getFullYear();
  filteredCity$!: Observable<City[]>;
  @ViewChild('treeView0') treeView0!: TreeViewComponent;
  @ViewChild('treeView1') treeView1!: TreeViewComponent;

  importForm = new FormGroup({
    category: new FormControl<null | Category>(null, Validators.required),
    subCategory: new FormControl<null | SubCategory>(null),
    location: new FormControl<string | null>(null, Validators.required),
    city: new FormControl<City | string>({ value: '', disabled: false }, [cityValidator()]),
    pointOfInterest: new FormControl<string | null>(null),
    public: new FormControl<number>(0),
    room: new FormControl<string | null>(null, Validators.required),
    floor: new FormControl<string | null>(null, Validators.required),
    rack: new FormControl<string | null>(null, Validators.required),
    case: new FormControl<string | null>(null, Validators.required),
    public_year: new FormControl<number | null>({ value: null, disabled: true }),
  })

  constructor(private connectServerService: ConnectServerService,
    private popupDialogService: PopupDialogService,
    private searchAutocompleteService: SearchAutocompleteService) {
    this.getCategories();
    this.formLogic();
  }
  ngOnInit(): void {
    this.searchCity();
  }
  private formLogic() {
    this.importForm.get('category')?.valueChanges.subscribe(
      (val: Category | null) => {
        if (val?.id) {
          this.getSubcategories(val.id);
        }
      }
    )
  }

  private getCategories() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'categories', {})
      .subscribe(
        (val: ApiResponse<{ categories: Category[] }>) => {
          this.categoryOptions = val.data.categories;
        }
      )
  }

  private getSubcategories(idcategory: number): void {
    this.connectServerService.getRequest<ApiResponse<{ subCategories: SubCategory[] }>>
      (Connect.urlServerLaraApi, 'subCategories', { id: idcategory })
      .subscribe(
        (val: ApiResponse<{ subCategories: SubCategory[] }>) => {
          if (val.data && val.data.subCategories) {
            this.subcategoryOptions = val.data.subCategories;
          }
        }
      )
  }

  enableDisablePublication() {
    if (this.importForm.get('public')!.value == 0) {
      this.importForm.get('public_year')?.disable();
      this.importForm.get('public_year')?.setValue(null);
      this.importForm.get('public_year')?.clearValidators()
    }
    else {
      this.importForm.get('public_year')?.enable();
      this.importForm.get('public_year')?.setValue(this.year);
      this.importForm.get('public_year')?.addValidators(Validators.required);
    }
  }

  isCorrectPublicYear() {
    if (/^[0-9]+$/.test(this.importForm.get('public_year')!.value!.toString())) {
      if (this.importForm.get('public_year')?.value! < this.year || this.importForm.get('public_year')?.value! > 9999) {
        this.importForm.get('public_year')?.setErrors({ invalidYear: true });
      }
      else this.importForm.get('public_year')?.setErrors(null);
    }
    else this.importForm.get('public_year')?.setErrors({ invalidYear: true });
  }

  private searchCity() {
    // ricerca
    const campo_city = this.importForm.get('city');
    if (campo_city) {
      this.filteredCity$ = campo_city.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value?.name || ''),
          filter(value => value.length > 0),
          debounceTime(600),
          switchMap((value: string) =>
            value ? this.searchAutocompleteService.getCity(value) : [])
        );
    }
  }

  displayCityName(city?: City): string {
    return city ? city.name : '';
  }

  insertArticle(event: { path: string }) {
    if (this.importForm.valid) {
      const path = event.path;
      const form_val = this.importForm.getRawValue();
      console.log(form_val);
      const obj_request: ApiResponse<any> = {
        code: 244,
        data: {},
        title: 'Info',
        message: "Sei sicuro di voler copiare nell' archivio permanente " +
          "la scheda: " + path + ", con i dati inseriti in precedenza? " +
          "Azione irreversibile, successivamente la scheda sarà cancellata dall'archivio Temporaneo.",
        obj_dialog: {
          disableClose: 0,
          obj_buttonAction:
          {
            action: 1,
            action_type: 2,
            label: 'Copia e cancella',
            run_function: () => this.saveAndDeleteFolder(path, form_val)
          }
        }
      }
      this.popupDialogService.popupDialog(obj_request);
    }

  }



  private saveAndDeleteFolder(path: string, formVal: any) {
    console.log('esegui: ', path);
    // this.connectServerService.postRequest<ApiResponse<null>>(Connect.urlServerLaraApi, 'copyFolder',
    //   {
    //     article: formVal,
    //     path: path
    //   }).
    //   subscribe(
    //     (val: ApiResponse<{ id: number }>) => {
    //       this.treeView0.refresh();
    //       this.treeView1.refresh();
    //     },
    //   );
  }
}
