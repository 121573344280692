import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Card } from '../../interfaces/home';
import { Connect } from '../../classes/connect';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent implements OnInit{

  urlServerLaraApiMultimedia = Connect.urlServerLaraApiMultimedia;

  @Input() obj_card!: Card;

  constructor(private router: Router) {
  }
  ngOnInit(): void {
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }

  changeCursor(cursorType: string) {
    document.body.style.cursor = cursorType;
  }



}
