import { ChangeDetectorRef, Component, HostListener, NgZone } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime, filter, map, Observable, startWith, switchMap } from 'rxjs';
import { ArticlesService } from '../../../services/articles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionsService } from '../../../services/options.service';
import { UploadImageService } from '../../../services/upload-image.service';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Connect } from '../../../classes/connect';
import { ConnectServerService } from '../../../services/connect-server.service';
import { ApiResponse } from '../../../interfaces/api-response';
import { MatSelectModule } from '@angular/material/select';
import { Article, Category, City, SubCategory } from '../../../interfaces/article';
import { PopupDialogService } from '../../../services/popup-dialog.service';
import { cityValidator } from '../../../validators/city-validator';
import { SearchAutocompleteService } from '../../../services/search-autocomplete.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { it } from 'date-fns/locale';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ArticleFile } from '../../../interfaces/image';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-article-historical-update',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTooltipModule,
],
  templateUrl: './article-historical-update.component.html',
  styleUrl: './article-historical-update.component.scss',
  providers:
    [
      { provide: MAT_DATE_LOCALE, useValue: it },
      { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
      { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
    ],
})
export class ArticleHistoricalUpdateComponent {

  showBorder: boolean = false;
  currentImg!: number;
  urlServerLaraApiMultimedia = Connect.urlServerLaraApiMultimedia;
  year = new Date().getFullYear();
  selectedFiles: File[] = [];
  categoryOptions: Category[] = [];
  subcategoryOptions: SubCategory[] = [];
  filteredCity$!: Observable<City[]>;
  yearFormat: string | null = null;
  article_info!: Article;
  firstcall = 0;

  errorMessage: string = '';
  files: ArticleFile[] = [];
  place: string = '';

  yearOptions: any[] = [];

  filteredYearOptions: Observable<string[]> = new Observable;
  filteredCategoryOptions: Observable<string[]> = new Observable;
  idarticle: number | null = null;

  articleForm = this.fb.group({
    category: new FormControl<null | number>(null, Validators.required),
    subCategory: new FormControl<null | number>(null),
    title: new FormControl('', Validators.required),
    description: new FormControl<string | null>(null),
    dateType: new FormControl<number | null>(null),
    city: new FormControl<City | string>({ value: '', disabled: true }, [cityValidator()]),
    pointOfInterest: new FormControl<string | null>(null),
    location: new FormControl<string | null>(null),
    year: new FormControl<string | null>(null, Validators.pattern('^[0-9]{1,4}$')),
    date: new FormControl<Date | null | string>(null),
    yearFrom: new FormControl<string | null>(null, Validators.pattern('^[0-9]{1,4}$')),
    yearTo: new FormControl<string | null>(null, Validators.pattern('^[0-9]{1,4}$')),
    public: new FormControl<number>(0),
    room: new FormControl<string | null>(null, Validators.required),
    floor: new FormControl<string | null>(null, Validators.required),
    rack: new FormControl<string | null>(null, Validators.required),
    case: new FormControl<string | null>(null, Validators.required),
    code: new FormControl<string | null>(null, Validators.required),
    public_year: new FormControl<number | null>({ value: null, disabled: true }),
  })


  constructor(private articlesService: ArticlesService, private route: ActivatedRoute, private optionsService: OptionsService,
    private uploadImage: UploadImageService, private connectServerService: ConnectServerService,
    private popupDialogService: PopupDialogService, private searchAutocompleteService: SearchAutocompleteService,
    private fb: FormBuilder, private router: Router, private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize();
  }

  checkWindowSize(): void {
    this.showBorder = window.innerWidth >= 920;  // Imposta showBorder su true se la larghezza è >= 920px
  }

  ngOnInit(): void {
    this.getCategories();
    this.searchCity();
    const parameters = this.route.snapshot.paramMap.get('id');
    if (parameters) {
      this.route.params.subscribe(
        (params) => {
          //console.error('params: ', params['name'])
          this.idarticle = params['id'];
          this.getInfoArticle();
          this.formLogic();
        }
      );
    }

    this.checkWindowSize();

    this.yearOptions = this.optionsService.getYearOptions();
    // let yearFrom = this.articleForm.get('yearFrom');
    // let yearTo = this.articleForm.get('yearTo');
    // if (yearFrom) {
    //   this.filteredYearOptions = yearFrom.valueChanges.pipe(
    //     startWith(''),
    //     map(value => this.filterYear(value || '')),
    //   );
    // }
    // if (yearTo) {
    //   this.filteredYearOptions = yearTo.valueChanges.pipe(
    //     startWith(''),
    //     map(value => this.filterYear(value || '')),
    //   );
    // }

    const allControls = ['subCategory', 'title', 'description', 'city', 'pointOfInterest', 'location', 'date', 'yearFrom', 'yearTo',
      'year', 'public', 'room', 'floor', 'rack', 'case'];

    if (this.idarticle == null) {
      allControls.forEach(control => {
        const controlInstance = this.articleForm.get(control);
        controlInstance?.disable();
      })
    }

    this.articleForm.get('category')?.valueChanges.subscribe(value => {
      allControls.forEach(control => {
        const controlInstance = this.articleForm.get(control);
        if (value) {
          controlInstance?.enable();
          // controlInstance?.setValue('');
        } else {
          controlInstance?.disable();
          // controlInstance?.setValue('');
        }
      });
    });
  }

  goToArticle() {
    this.router.navigate(["archivioStorico/scheda", this.idarticle])
  }

  // onFileSelected(event: any) {
  //   const uploadCallback = () => {
  //     this.images = this.uploadImage.getImages();
  //     if (this.cover == '') {
  //       this.cover = this.uploadImage.getCover();
  //       this.coverIndex = 0;
  //     }
  //   }
  //   const files: FileList = event.target.files;
  //   if (files.length > 0) {
  //     this.errorMessage = this.uploadImage.upload(files, uploadCallback);
  //   }
  // }

  changeCover(id: number) {
    this.uploadImage.changeCover(id).
      subscribe((val: ApiResponse<null>) => {
        this.articlesService.getFilesServer(this.idarticle!).subscribe((val: ApiResponse<{ listFiles: ArticleFile[] }>) => {
          if (val.data.listFiles) {
            this.files = val.data.listFiles;
          }
        })
      })
  }

  deleteImg(id: number) {
    const obj_request: ApiResponse<any> = {
      code: 244,
      data: {},
      title: 'Info',
      message: 'Sei sicuro di voler cancellare l\'immagine?',
      obj_dialog: {
        disableClose: 0,
        obj_buttonAction:
        {
          action: 1,
          action_type: 2,
          label: 'Cancella',
          run_function: () => this.deleteImgFromServer(id)
        }
      }
    }
    this.popupDialogService.popupDialog(obj_request);
  }

  private deleteImgFromServer(id: number) {
    this.uploadImage.deleteFile(id).subscribe((val: ApiResponse<null>) => {
      this.articlesService.getFilesServer(this.idarticle!).subscribe((val: ApiResponse<{ listFiles: ArticleFile[] }>) => {
        if (val.data.listFiles) {
          this.files = val.data.listFiles;
          this.currentImg = this.files.find((val: ArticleFile) => { return val.cover === 1 })?.id!;
        }
      })
    })
  }

  private getInfoArticle() {
    this.firstcall = 0;
    this.articlesService.getArticleInfoServer(this.idarticle!).subscribe(
      (val: ApiResponse<{ article: Article, listFiles: ArticleFile[] }>) => {
        //console.log('info', val.data);
        if (val.data.article) {
          this.article_info = val.data.article;
          if (this.article_info.date_type != null) {
            if (this.article_info.date_type == 0) {
              this.changeFormat('date');
              this.yearFormat = 'date';
            } else if (this.article_info.date_type == 1) {
              this.changeFormat('year');
              this.yearFormat = 'year';
            } else if (this.article_info.date_type == 2) {
              this.changeFormat('period');
              this.yearFormat = 'period';
            }
          }
          this.articleForm.patchValue({
            category: this.article_info.category.id,
            title: this.article_info.title,
            description: this.article_info.description,
            city: this.article_info.city,
            pointOfInterest: this.article_info.pointofinterest,
            location: this.article_info.storage_place,
            date: this.article_info.date_article,
            year: this.article_info.year_article,
            yearFrom: this.article_info.yearfrom_article,
            yearTo: this.article_info.yearto_article,
            public: this.article_info.public,
            room: this.article_info.room,
            floor: this.article_info.floor,
            rack: this.article_info.rack,
            case: this.article_info.case,
            code: this.article_info.code,
            public_year: this.article_info.public_year
          });
          if (val.data.listFiles) {
            this.files = val.data.listFiles;
            this.currentImg = this.files.find((val: ArticleFile) => { return val.cover === 1 })?.id!;
          }
          this.articleForm.markAsPristine();
        }
      }
    )
  }

  enableDisablePublication() {
    if (this.articleForm.get('public')!.value == 0) {
      this.articleForm.get('public_year')?.disable();
      this.articleForm.get('public_year')?.setValue(null);
      this.articleForm.get('public_year')?.clearValidators()
    }
    else {
      this.articleForm.get('public_year')?.enable();
      this.articleForm.get('public_year')?.setValue(this.year);
      this.articleForm.get('public_year')?.addValidators(Validators.required);
    }
  }

  isCorrectPublicYear() {
    if (/^[0-9]+$/.test(this.articleForm.get('public_year')!.value!.toString())) {
      if (this.articleForm.get('public_year')?.value! < this.year || this.articleForm.get('public_year')?.value! > 9999) {
        this.articleForm.get('public_year')?.setErrors({ invalidYear: true });
      }
      else this.articleForm.get('public_year')?.setErrors(null);
    }
    else this.articleForm.get('public_year')?.setErrors({ invalidYear: true });
  }

  isCorrectPeriodLogic() {
    if (parseInt(this.articleForm.get('yearFrom')?.value!) > 99 && parseInt(this.articleForm.get('yearTo')?.value!) > 99
        && parseInt(this.articleForm.get('yearFrom')?.value!) < 9999 && parseInt(this.articleForm.get('yearTo')?.value!) < 9999 &&
        (/^[0-9]+$/.test(this.articleForm.get('yearFrom')!.value!.toString()) && /^[0-9]+$/.test(this.articleForm.get('yearTo')!.value!.toString()))) {
      if ((parseInt(this.articleForm.get('yearFrom')?.value!) - parseInt(this.articleForm.get('yearTo')?.value!)) >= 0) {
        this.articleForm.get('yearTo')?.setErrors({ invalidPeriod: true });
        this.articleForm.get('yearFrom')?.setErrors({ invalidPeriod: true });
      }
      else {
        this.articleForm.get('yearTo')?.setErrors(null);
        this.articleForm.get('yearFrom')?.setErrors(null);
      }
    }
    else {
      this.articleForm.get('yearTo')?.setErrors({ invalidPeriod: true });
      this.articleForm.get('yearFrom')?.setErrors({ invalidPeriod: true });
    }
  }

  isCorrectYearLogic() {
    if (parseInt(this.articleForm.get('year')?.value!) > 99 && parseInt(this.articleForm.get('year')?.value!) < 9999  && (/^[0-9]+$/.test(this.articleForm.get('year')!.value!.toString()))) {
        this.articleForm.get('year')?.setErrors(null);
      }
    else this.articleForm.get('year')?.setErrors({ invalidYear: true });
  }


  changeFormat(type: string) {
    if (type === "date") {
      this.resetYearValue(type);
      if (this.yearFormat === "date") {
        this.articleForm.get('dateType')!.setValue(null);
        this.yearFormat = null;
      } else {
        this.yearFormat = type;
        this.articleForm.get('dateType')!.setValue(0);
      }
    } else if (type === "year") {
      this.resetYearValue(type);
      if (this.yearFormat === "year") {
        this.articleForm.get('dateType')!.setValue(null);
        this.articleForm.get('year')!.setValue(null);
        this.yearFormat = null;
      } else {
        this.yearFormat = type;
        this.articleForm.get('dateType')!.setValue(1);
      }
    } else if (type === "period") {
      this.resetYearValue(type);
      if (this.yearFormat === "period") {
        this.articleForm.get('dateType')!.setValue(null);
        this.yearFormat = null;
      } else {
        this.yearFormat = type;
        this.articleForm.get('dateType')!.setValue(2);
      }
    }
  }

  private resetYearValue(type: string) {
    if (type === "date") {
      this.articleForm.get('yearFrom')!.setValue(null);
      this.articleForm.get('yearTo')!.setValue(null);
      this.articleForm.get('year')!.setValue(null);
    } else if (type === "year") {
      this.articleForm.get('yearFrom')!.setValue(null);
      this.articleForm.get('yearTo')!.setValue(null);
      this.articleForm.get('date')!.setValue(null);
    } else if (type === "period") {
      this.articleForm.get('year')!.setValue(null);
      this.articleForm.get('date')!.setValue(null);
    }
  }

  formLogic() {
    this.articleForm.get('category')?.valueChanges.subscribe(
      (val: number | null) => {
        if (val) {
          this.articleForm.patchValue({
            subCategory: null
          });
          this.getSubcategories(val);
        }
      }
    )
  }

  /**
   * Salva l'articolo
   */
  saveArticle() {
    // console.log(this.articleForm.getRawValue())
    const article_send = {
      refidcategory: this.articleForm.get('category')?.value!,
      refidsubcategory: this.articleForm.get('subCategory')?.value!,
      title: this.articleForm.get('title')?.value!,
      description: this.articleForm.get('description')?.value!,
      date_type: this.articleForm.get('dateType')?.value!,
      city: this.articleForm.get('city')?.value!,
      pointofinterest: this.articleForm.get('pointOfInterest')?.value!,
      storage_place: this.articleForm.get('location')?.value!,
      date_article: this.articleForm.get('date')?.value!,
      year_article: this.articleForm.get('year')?.value!,
      yearfrom_article: this.articleForm.get('yearFrom')?.value!,
      yearto_article: this.articleForm.get('yearTo')?.value!,
      public: this.articleForm.get('public')?.value!,
      room: this.articleForm.get('room')?.value!,
      floor: this.articleForm.get('floor')?.value!,
      rack: this.articleForm.get('rack')?.value!,
      case: this.articleForm.get('case')?.value!,
      code: this.articleForm.get('code')?.value!,
      public_year: this.articleForm.get('public_year')?.value!,
    }
    if (this.articleForm.valid) {
      this.connectServerService
        .postRequest<ApiResponse<null>>(Connect.urlServerLaraApi, 'saveArticleHistorical',
          {
            id: this.idarticle,
            article: article_send,
          }
        ).subscribe(
          (val: ApiResponse<null>) => {
            this.popupDialogService.popupDialog(val);
            this.getInfoArticle();
          }
        )
    }
  }

  private uploadFilesServer() {
    const formData = new FormData();
    formData.append('id', String(this.idarticle));
    this.selectedFiles.forEach((file, index) => {
      formData.append(`files[]`, file);
    });
    const upload$ = this.connectServerService
      .postRequest<ApiResponse<null>>(Connect.urlServerLaraApi, 'uploadFilesArticle', formData);
    upload$
      .subscribe(
        (res: ApiResponse<null>) => {
          this.popupDialogService.popupDialog(res);
          this.resetFileInput();
          this.articlesService.getFilesServer(this.idarticle!).
            subscribe(
              (val: ApiResponse<{ listFiles: ArticleFile[] }>) => {
                this.files = val.data.listFiles;
                this.currentImg = this.files.find((val: ArticleFile) => { return val.cover === 1 })?.id!;
              }
            );
        }
      );
  }

  visualizeImg(id: number) {
    this.currentImg = id;
  }

  /**
   * Quando si seleziona i file
   * @param event
   */
  onFileSelected(event: Event): void {
    const maxFiles = 10;
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.selectedFiles = Array.from(input.files);
      if (this.selectedFiles.length > maxFiles) {
        const obj_request: ApiResponse<any> = {
          code: 240,
          data: {},
          title: 'Warning',
          message: 'Non puoi importare più di ' + maxFiles + ' files. Seleziona meno immagini e riprova.'
        }
        this.popupDialogService.popupDialog(obj_request);
      } else {
        this.uploadFilesServer();
      }

    }
  }
  /**
   * Reset la selezione dei file quando importato
   */
  private resetFileInput() {
    const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
    fileInput.value = '';
    this.selectedFiles = [];
  }

  /**
   * Lista categorie
   */
  private getCategories() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'categories', {})
      .subscribe(
        (val: ApiResponse<{ categories: Category[] }>) => {
          this.categoryOptions = val.data.categories;
        }
      )
  }
  /**
   * Lista sottocategorie
   * @param idcategory
   */
  private getSubcategories(idcategory: number): void {
    this.connectServerService.getRequest<ApiResponse<{ subCategories: SubCategory[] }>>
      (Connect.urlServerLaraApi, 'subCategories', { id: idcategory })
      .subscribe(
        (val: ApiResponse<{ subCategories: SubCategory[] }>) => {
          if (val.data && val.data.subCategories) {
            this.subcategoryOptions = val.data.subCategories;
            if (this.firstcall === 0) {
              this.firstcall = 1;
              if (this.article_info && this.article_info.subcategory) {
                this.articleForm.patchValue({
                  subCategory: this.article_info.subcategory.id
                })
              }
            }

          }
        }
      )
  }
  private searchCity() {
    // ricerca
    const campo_city = this.articleForm.get('city');
    if (campo_city) {
      this.filteredCity$ = campo_city.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value?.name || ''),
          filter(value => value.length > 0),
          debounceTime(600),
          switchMap((value: string) =>
            value ? this.searchAutocompleteService.getCity(value) : [])
        );
    }
  }
  /**
   * Visualizzazione per barra ricerca city
   * @param city
   * @returns
   */
  displayCityName(city?: City): string {
    return city ? city.name : '';
  }

}
