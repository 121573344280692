import { Component } from '@angular/core';
import { Connect } from '../../../classes/connect';
import { ConnectServerService } from '../../../services/connect-server.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-inventory-artistic',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './inventory-artistic.component.html',
  styleUrl: './inventory-artistic.component.scss'
})
export class InventoryArtisticComponent {
  inventoryArtistic: any[] = [];

  constructor(private connectServerService: ConnectServerService) {
    this.getInventoryArtistic();
  }

  private getInventoryArtistic() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'inventoryArtistic', {})
      .subscribe((val: any) => {
        this.inventoryArtistic = val.data.inventoryArtistic;
        this.inventoryArtistic.forEach(element => {
          element.isOpen = true;
        })
      })
  }

  openClose(element: any) {
    element.isOpen = !element.isOpen;
  }

  ngAfterViewChecked() {
    this.adjustContentHeight();
  }

  private adjustContentHeight() {
    const elements = document.querySelectorAll('.content-wrapper');
    elements.forEach((element: any) => {
      if (element.classList.contains('open')) {
        element.style.height = `${element.scrollHeight}px`;
      } else {
        element.style.height = '0';
      }
    });
  }
}
