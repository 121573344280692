import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectCulturalDefinition } from '../../interfaces/select-cultural-definition';
import { SelectOptions } from '../../interfaces/select-options';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { CheckFormValidityService } from '../../../../services/check-form-validity.service';
import { PopupDialogService } from '../../../../services/popup-dialog.service';
import { Connect } from '../../../../classes/connect';
import { ApiResponse } from '../../../../interfaces/api-response';


@Component({
  selector: 'app-legal-condition',
  standalone: true,
  imports: [
    MatExpansionModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './legal-condition.component.html',
  styleUrl: './legal-condition.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalConditionComponent {

  readonly panelOpenState = signal(false);

  @Input() idarticle: number = 0;
  @Input() listLegal: SelectOptions[] = [];

  saved: boolean = false;
  submitted = false;

  legalForm: FormGroup;

  constructor(private fb: FormBuilder, private connectServerService: ConnectServerService,
    private popupDialogService: PopupDialogService, private checkFormValidityService: CheckFormValidityService) {
    this.legalForm = this.fb.group({
      acquisitionType: [null, Validators.required],
      name: [null, Validators.maxLength(50)],
      acquisitionDate: [null, Validators.maxLength(25)],
      acquisitionLocation: [null, Validators.maxLength(50)]
    });
  }

  ngOnInit(): void {
    //TEMPORANEO
    this.panelOpenState.set(true);
  }

  private getLegalInfo() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, '', { idarticle: this.idarticle })
      .subscribe(
        (val: ApiResponse<{ legalForm: any }>) => {
          this.legalForm.patchValue(val.data.legalForm);
          this.saved = this.checkFormValidityService.areAllRequiredFieldsFilled(this.legalForm);
          if(this.saved) {
            this.panelOpenState.set(false);
          }
          else {
            this.panelOpenState.set(true);
          }
        }
      )
  }

  private setLegalInfo() {
    const formValues = this.legalForm.getRawValue();
    this.connectServerService.postRequest(Connect.urlServerLaraApi, '',
      { idarticle: this.idarticle, infoGeneral: formValues })
      .subscribe(
        (val: ApiResponse<null>) => {
          this.popupDialogService.popupDialog(val);
          this.getLegalInfo();
        }
      )
  }

  save() {
    this.submitted = true;
    if(this.legalForm.valid) {
      this.setLegalInfo();
    }
  }

}
